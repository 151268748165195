.m-subscriptions {
    margin: 40px 15px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);

    &__header {
        background-color: $secondary-color;
        color: $white;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.5px;
        padding: 10px;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        &--titleBtn {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__item {
        padding: 10px;
        background-color: $white;

        &:not(:last-child) {
            border-bottom: 1px solid $gray-10;
        }
    }

    &__itemInfo {

        .value {
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.5px;

            &.-active {
                color: #48ca72;
            }

            &.-expired {
                color: $primary-color;
            }

            &.-pending {
                color: #a1a1a1;
            }
        }

        .duration {
            display: inline;
            font-size: 13px;
            letter-spacing: 0.2x;
            color: $gray-400;
        }

        .title {
            display: block;
            font-size: 13px;
            letter-spacing: 0.2x;
            color: $gray-400;
        }

        .m-selectGroup {
            margin-top: 0;

            input {
                height: 40px;
            }

            .m-selectGroup__options {
                &.-open {
                    top: 41px;
                }
            }
        }
    }

    
}