.m-topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    letter-spacing: -0.2px;
    line-height: 20px;
    color: $gray-7;

    svg {
      color: $secondary-color;
    }
  }

  &.-block {
    display: block;
  }
}