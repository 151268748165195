.m-map {
    position: relative;
    margin-top: 20px;

    .m-checkboxGroup {
        width: 100%;
        margin-top: 15px;

        label {
            font-size: 13px;
            line-height: 16px;
        }
    }

    .m-inputGroup {
        margin-top: 20px;

        input {
            margin-top: 10px;
        }
    }

    &__address {
        display: flex;
        align-items: center;
        font-size: 16px;
        letter-spacing: -0.2px;
        line-height: 22px;
        padding-right: 10px;
    }

    &__addressIconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        height: 34px;
        width: 34px;
        min-height: 34px;
        min-width: 34px;
        border-radius: 50%;
        background-color: $main-color;

        svg {
            min-width: 12px;
            min-height: 14px;
        }
    }

        &__adressInfo {
            &--name {
                font-weight: 600;
                padding-bottom: 5px;
            }
        }

    &__googleMapWrapper {
        height: 420px;
        padding: 10px;
        background-color: $white;
        border: 1px solid #E1E1E9;

        &,
        & > div {
            border-radius: 5px;
        }
    }

    &__removeMarker {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 44px;
        margin-top: 15px;
        border-radius: 5px;
        background-color: $red-1;
        color: $red-3;
        font-size: 13px;
        line-height: 15px;
        font-weight: 600;
        border: 0;
        cursor: pointer;
        transition: background-color .3s;

        &:hover {
            background-color: darken($red-1, 10%);
        }

        &:focus {
            outline: 0;
        }

        &.-viewMore {
            background-color: $secondary-color;
            color: $white;

            &:hover {
                background-color: darken($secondary-color, 10%);
            }
        }
    }

    &__addressInput {
        position: absolute;
        z-index: 1;
        bottom: 40px;
        left: 20px;
        width: 170px;

        input {
            border: 1px solid #ababab;
        }

        .confirm {
            cursor: pointer;

            &:hover {
                svg {
                    stroke: $secondary-color;
                }
            }
        }
    }

    .gm-style-iw {
        width: 280px;
        padding: 12px 20px;
    }

    .gm-ui-hover-effect {
        transform: translate(-5px, 5px) scale(1.25);
    }
}