.m-tab {
    background-color: $white;
    border: 1px solid $gray-10;
    display: flex;
    flex-direction: column;
    border-radius: 0 0 5px 5px;
    overflow: hidden;

    @include media-up(l) {
        flex-direction: row;
    }

    &__tab {
        position: relative;
        padding: 10px;
        font-size: 14px;
        line-height: calc(22/16);
        font-weight: 600;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: rgba($blue-4, .5);
        transition: color .3s;
        cursor: pointer;
        text-align: center;
        z-index: 2;

        @include media-up(m) {
            text-align: initial;
            padding: 15px 25px;
        }

        @include media-up(xl) {
            padding: 24px 40px 22px;
            font-size: 16px;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            height: 100%;
            width: 100%;
            background-color: $primary-color;
            transition: transform .3s;
            transform: scaleX(0);
            pointer-events: none;
            z-index: -1;

            @include media-up(m) {
                height: 3px;
            }
        }

        &.-active {
            color: $white;

            @include media-up(m) {
                color: $blue-4;

            }

            &::after {
                transform: scaleX(1);
            }
        }
    }

    &.-subcards {
        margin: 30px 0;
        background-color: transparent;
        border-color: transparent;
        border-bottom: 1px solid $gray-10;
    }

    &.-companyForm {

        @include media-down(xl) {
            flex-direction: column;
        }

        .m-tab__tab {
            @include media-up(xl) {
                padding: 15px 25px;
                font-size: 14px;
            }
        }
    }
}