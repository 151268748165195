.m-content {

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;

    }

    &__header {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .a-btnSave {
            position: absolute;
            right: 0;
        }
    }
}