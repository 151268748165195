.m-fileDragAndDrop {

    .dropzone {
        display: flex;
        align-items: center;
        height: 150px;
        padding: 5px 15px;
        background-color: $white;
        border: 1px dashed $gray-11;
        border-radius: 5px;
        color: $black;
        font-size: 14px;
        line-height: 18px;
        transition: border-color .3s;
        cursor: pointer;

        &:hover,
        &:focus {
            border-color: $secondary-color;
            outline: none;
        }

        &.-active {
            border-color: $secondary-color;
        }

        &.-file {
            flex-direction: column;
            justify-content: center;

            .file {
                padding: 0;
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
    }

    .imageContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        width: 70px;
        margin-right: 15px;
        background: none;

        &.-noImage {
            background-image: url('../../images/Upload\ Photos.svg');
            background-size: cover;
        }
    }

    .text {

        p,
        span {
            font-size: 13px;
            line-height: 16px;
            display: inline;
        }

        span {
            margin-left: 5px;
            color: $secondary-color;
            text-decoration: underline;
        }
    }

    .file {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px 20px;
        margin-right: 20px;
    }

    .image {
        width: 100%;
        height: 100%;
        border-radius: 6px;
        object-fit: contain;
    }
}