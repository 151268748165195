.m-modal {
  position: fixed;
  left: 0;
  top: 0;
  padding: calc(#{$topbar-height-mobile} + 30px) 0 10vh;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0;
  z-index: 20;
  pointer-events: none;
  transition: opacity .3s;
  animation: fadeIn 0.3s;

  @include media-up(l) {
    padding: calc(#{$topbar-height-desktop} + 20px) 0 10vh;
  }

  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }

  @keyframes zoomIn {
    from {transform: scale(0.70);}
    to {transform: scale(1);}
  }

  @include media-up(l) {
    top: 0;

  }

  &.-show {
    opacity: 1;
    pointer-events: initial;
    cursor: default;

    .m-modal__content {
      transform: translateY(0)
    }
  }

  &.-sideModal {
    left: auto;
    right: 0;
    top: 120px;
    padding-top: 0;

    @include media-up(l) {
      top: 70px;
    }

    .m-modal__content {
      position: absolute;
      top: 0;
      right: 0;
      margin: 0;
      padding: 20px;
      border-radius: 0;
      box-shadow: none;
      //height: calc(100vh - 70px);
      height: auto;
      max-height: initial;
      max-width: 390px;
      width: calc(100vw - 64px);

      @include media-up(l) {
        padding: 50px;
      }

      &.-password {
        height: 100%;
      }
    }
  }

  &.-small {

    .m-modal__body {
      padding: 10px 16px;
    }

    .m-modal__content {
      min-height: initial;
      width: 600px;
    }

    .m-modal__footer {
      height: auto;
      border-top: 0;
    }

    .m-modal__payPal {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 30px;

      h2 {
        font-size: 34px;
      }

      .title {
        margin: 0 0 40px;
      }

      .a-btn {
        border-radius: 6px;
        max-width: 300px;

        &.-secondary {
          background-color: rgba(40, 40, 40, 0.1);
          color: #282828;
        }

        &:hover {
          background-color: #161616;
          color: #fff;
        }
      }
    }
  }

  &.-custom {

    .m-modal__body {
      padding: 10px 16px;
    }

    .m-modal__content {
      min-height: initial;
      height: fit-content;
      min-width: 400px;
      width: fit-content;
    }

    .m-modal__footer {
      height: auto;
      border-top: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    background-color: $white;
    margin: auto;
    padding: 0;
    width: 1260px;
    max-width: calc(100% - 40px);
    min-height: 550px;
    max-height: 80vh;
    height: auto;
    overflow: auto;
    box-shadow: 0 4px 8px 0 rgba($black, 0.2), 0 6px 20px 0 rgba($black, 0.19);
    transition: transform .3s;
    border-radius: 6px;
    transform: translateY(-100%);
    overflow-y: auto;
    animation: zoomIn 0.3s;
    transform-origin: top center;
    @include scrollbar;

    @include media-up(m) {
      max-width: calc(100% - 160px);
    }

    @include media-up(xl) {
      max-height: unset;
    }

    @keyframes zoomIn {
      from {transform: scale(0.70);}
      to {transform: scale(1);}
    }
  }

  &__close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    transition: transform .3s;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 2px;
      background-color: $blue-4;
    }

    &::before {
      transform: translateX(-50%) rotate(45deg);
    }

    &::after {
      transform: translateX(-50%) rotate(-45deg);
    }

    &:hover {
      transform: rotate(90deg);
    }
  }

  &__header {
    padding: 2px 16px;
    background-color: $color-primary;
    color: $white;
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
    max-width: 300px;
    span {

      span {
        font-weight: bold;
        font-size: 18px;
    }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 16px;
    border-top: 1px solid $gray-10;
    background-color: $white;

    @include media-up(m) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      min-height: 84px;
      height: auto;
    }
  }

  &__footerButtons {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 20px;

    .a-btn,
    span {

      min-height: 44px;
      margin-top: 15px;
      min-width: 200px;

      @include media-up(m) {
        min-width: 0;
        min-height: 0;
        margin-top: 0;
      }
    }

    @include media-up(m) {
      min-width: 295px;
      max-height: 44px;
      align-items: initial;
      flex-direction: row;
      padding-bottom: 0;
    }

    .a-btn {
      width: 100px;
      padding: 0;
      font-weight: 600;
    }

  }

  &__clear,
  &__cancel {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -0.5px;
    line-height: 17px;
    cursor: pointer;
  }

  &__clear {
    height: 45px;
    width: 91px;
    border: 1px solid $gray-10;
    border-radius: 5px;
    margin: 0 10px;
    transition: border-color .3s;

    &:hover {
      border-color: $blue-4;
    }
  }

  &__cancel {
    height: 44px;
    width: 85px;
    opacity: 0.25;
    border-radius: 5px;
    background-color: $gray-10;
    transition: opacity .3s;

    &:hover {
      opacity: 1;
    }
  }
}