.m-together {
    margin: 0 15px;

    &__top {
        padding: 100px 0 75px;
        justify-content: space-between;
        align-items: center;
        text-align: center;

        @include media-up(xl) {
            text-align: initial;
        }

        h1 {
            letter-spacing: -0.83px;
            line-height: 58px;
            margin-bottom: 30px;

            @include media-up(xl) {
                margin-bottom: 0;
            }
        }

        p {
            letter-spacing: 0;
            color: $blue-4;

            &:nth-of-type(1) {
                font-size: 20px;
                line-height: 30px;
                margin-bottom: 15px;
            }

            &:nth-of-type(2) {
                line-height: 26px;
                opacity: 0.7;
            }
        }
    }

    &__bottom {
        background-color: $gray-1;

        h1 {
            text-align: center;
            margin: 8px 0 52px;
            letter-spacing: -0.83px;
            line-height: 58px;
        }

        .-yellowText {
            text-align: center;
            padding-top: 80px;
        }

        &--card {
            padding-right: 0;
            position: relative;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            margin-bottom: 20px;
            text-align: center;
            padding: 50px 20px;
            background-color: $blue-4;

            @include media-up(s) {
                padding: 80px 40px;

            }

            @include media-up(xl) {
                padding: 179px 40px 35px 65px;
                margin-bottom: 0;
                text-align: initial;

                &:not(:last-child) {
                    &::after {
                        content: "";
                        position: absolute;
                        background-color: $gray-1;
                        width: 23px;
                        height: calc(100% + 7px);
                        right: -17px;
                        transform: rotate(-3deg);
                        top: -5px;
                        z-index: 11;
                    }
                }
            }

            &:nth-of-type(2) {
                h3 {
                    @include media-up(xxl) {
                        padding-right: 80px;
                    }
                }
            }

            &:nth-of-type(1) {
                h3 {
                    @include media-up(xxl) {
                        padding-right: 90px;
                    }
                }
            }

            &::before {
                content: "";
                position: absolute;
                background: linear-gradient(180deg, rgba($black, 0) 0%, $black 100%);
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
            }

            h3 {
                font-size: 32px;
                letter-spacing: -1px;
                line-height: 42px;
                margin-bottom: 30px;
            }

            p {
                &:nth-of-type(1) {
                    line-height: 26px;
                    margin-bottom: 20px;
                }

                &:nth-of-type(2) {
                    font-size: 14px;
                    line-height: 24px;
                    margin-bottom: 0;
                }
            }

            h3,
            p {
                color: $white;
                z-index: 1;
                position: relative;
            }

            &.-first {
                background-image: url(../../images/together-1.png);
            }


            &.-second {
                background-image: url(../../images/together-2.png);
            }


            &.-third {
                background-image: url(../../images/together-3.png);
            }
        }
    }
}