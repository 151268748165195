.m-main {
    position: relative;
    margin-top: 120px;
    width: 100%;
    margin-left: auto;
    background-color: $main-color;
    //transition: transform .5s;
    padding: 20px;
    min-height: calc(100vh - 120px);
    overflow-x: hidden;


    &.-sidenavOpen {
        width: calc(100% - 275px);
        transition: 0.5s;

        .m-sidebar {
            @include media-up(m) {
                width: calc(100% - 275px);
            }
            // height: 100%;
            // top: 0px;
        }

        .m-main__wrapper {
            padding-left: 15px;
        }

        @include media-down(m) {
            width: 100%;
            margin-left: 0;
            overflow-x: hidden;
        }
    }

    @include media-up(l) {
        margin-top: 70px;
        min-height: calc(100vh - 70px);
    }

    &__wrapper {
        display: flex;
        justify-content: center;

        @include media-up(m) {
            padding-left: 64px;
        }

        @include media-up(xxl) {
            // padding-left: 0;
        }

        ._wr {
            margin: 0;

            @include media-up (xxl) {
                margin: inherit;
            }
        }
    }

    &__content {
        width: 100%;
    }

    .btn-group {
        .a-btn:not(:last-child) {
            margin-right: 20px;
        }
    }

    .gm-style-pbc {
        +div {
            div {
                &:focus {

                    outline: 0;
                }
            }

            img {
                &:focus {

                    outline: 0;
                }
            }
        }
    }

    .gm-style {
        .gm-style-iw-c {
            top: -42px ;
        }
        
        .gm-style-iw-t::after {
            top:-42px !important;
        }

        .gm-style-iw-tc {
            top:-42px !important;
        }
    }
  }

.box {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
}