.p-dataOverview {
  display: flex;
  // align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  .m-selectGroup {
    margin: 0 0 30px;
  }

  h3 {
    margin-bottom: 30px;
    color: rgba($secondary-color, 0.7);
    font-size: 32px;
    font-weight: 600;
  }

  &__title {
    width: 100%;
    margin-bottom: 20px;
    font-size: 20px;
    color: rgba($secondary-color, 0.7);
  }

  .tech-monitors-title {
    display: flex;
    align-items: center;
    img {
      height: 40px;
      margin-right: 20px;
    }
  }

  &__box {
    width: 30%;
    margin-bottom: 20px;
    background-color: $white;
    border: 1px solid rgba(190, 190, 190, 0.5);
    border-radius: 5px;
    position: relative;

    &.-fullWidth {
      width: 100%;
    }

    &.-halfWidth {
      width: 49%;

      @include media-down(m) {
        width: 100%;
      }

      &.-md {
        @include media-down(m) {
          width: 100%;
        }
      }
    }
  }

  &__boxHeader {
    background-color: rgba($secondary-color, 0.1);
    padding: 10px;
  }

  &__boxTitle {
    font-size: 20px;
    font-weight: 600;
    color: rgba($secondary-color, 0.7);
  }

  &__boxDescription {
    font-size: 14px;
    font-weight: 400;
    color: rgb(145, 145, 145);
  }

  &__boxContent {
    padding: 10px;

    &.-hidden {
      filter: blur(3px);
    }
  }

  &__boxFilters {
    display: flex;
    margin: 10px 0 20px;
    width: 100%;

    span {
      background-color: rgba($secondary-color, 0.1);
      margin-right: 10px;
      padding: 3px 10px;
      font-size: 14px;
      border-radius: 5px;
      cursor: pointer;
      color: rgba($secondary-color, 0.7);
      font-weight: 600;

      &:hover {
        background-color: rgba($secondary-color, 0.3);
      }
    }

    span.active {
      background-color: rgba($primary-color, 0.8);
      color: $white;
    }
  }

  &__pieDrillDown {
    position: relative;
    padding: 150px 0 10px;

    .smallPieChart {
      position: relative;
      z-index: 1;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      margin-bottom: -265px;

      @media only screen and (max-width: 400px) {
        margin-bottom: -250px;
      }

      @include media-up(xs) {
        margin-bottom: -275px;
      }

      @include media-up(s) {
        margin-bottom: -285px;
      }

      @include media-up(m) {
        margin-bottom: -300px;
      }

      @include media-up(l) {
        margin-bottom: -300px;
      }

      &.-showLabel {
        .dxc-labels-group {
          g {
            visibility: visible;
          }

          .dxc-labels {
            g {
              g {
                transform: translate(10px, 104px);
              }
            }
          }
        }

      }
    }

    .bigPieChart {
      position: relative;
      // display: flex;
      // align-items: center;
      // justify-content: center;
    }
  }

  &__heatmap {
    overflow-x: auto;
    padding-left: 1px;
    @include scrollbar;
  }

  &__heatmapTable {
    width: 100%;
    margin-top: 100px;

    th {
      border: 0;
      transform: rotate(-30deg);
      white-space: nowrap;
      max-width: 100px;
      min-width: 100px;
      text-transform: capitalize;
      padding: 20px;
      font-weight: 600;
      font-size: 14px;

      &:before {
        content: '';
        display: inline-block;
      }

      &:first-of-type {
        position: sticky;
        top: 0;
        left: 0;
        z-index: 5;

        &:before {
          content: '';
          display: inline-block;
          height: 175px;
          width: 100%;
          background-color: $white;
          position: absolute;
          bottom: -5px;
          left: 25px;
          transform: rotate(30deg);
        }
      }
    }

    tbody {
      background-color: $white;
    }

    tr {
      background-color: transparent;
    }

    td {
      font-size: 12px;
      border: 1px solid $white;

      @include media-up(m) {
        font-size: 14px;
      }

      &.-name {
        padding: 13px;
        font-size: 10px;
        letter-spacing: -0.2px;
        line-height: 20px;
        text-align: left;
        white-space: nowrap;
        width: 200px;
        min-width: 200px;
        position: sticky;
        left: 0;
        white-space: break-spaces;
        background-color: white;
        z-index: 5;

        &::before {
          content: '';
          background-color: transparent;
          position: absolute;
          width: calc(100% + 2px);
          height: 100%;
          top: 0;
          left: -2px;
          border-right: 2px solid #d9dfe4;
          border-left: 2px solid $white;
        }

        @include media-up(m) {
          font-size: 12px;
          // padding-right: 20px;
        }
      }

      &.-color0 {
        background-color: rgba(228, 36, 52, 0.5);
      }

      &.-color00 {
        background-color: rgba(228, 36, 52, 0.2);
      }

      &.-color01 {
        background-color: rgba(6, 190, 74, 0.3);
      }

      &.-color02 {
        background-color: rgba(6, 190, 74, 0.4);
      }

      &.-color03 {
        background-color: rgba(6, 190, 74, 0.6);
      }

      &.-color04 {
        background-color: rgba(6, 190, 74, 0.7);
      }

      &.-color05 {
        background-color: rgba(6, 190, 74, 0.8);
        // color: $white;
      }

      &.-color06 {
        background-color: rgba(6, 190, 74, 1);
        // color: $white;
      }

      &.-color07 {
        background-color: rgba(7, 197, 77, 1);
        // color: $white;
      }

      &.-color08 {
        background-color: rgba(6, 172, 67, 1);
        // color: $white;
      }

      &.-color09 {
        background-color: rgba(5, 148, 57, 1);
        // color: $white;
      }
    }
  }

  &__previewTitle {
    color: rgba($secondary-color, 0.7);
    font-size: 24px;
    font-weight: 600;
  }

  &__previewContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background-color: $gray-200;
      border-radius: 4px;
      opacity: 0.5;
    }

    ::-webkit-scrollbar-thumb {
      background: $secondary-color;
      border-radius: 4px;
    }

    .chartForm {
      height: fit-content;
      width: 100%;
    }
  }

  &__preview {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    background-color: #00000038;
    padding: 15px 15px 5px 15px;
    border-radius: 5px;
    height: 80vh;
    overflow-y: auto;

    @include media-up(l) {
      width: 50%;
    }

    .p-dataOverview__box {
      cursor: pointer;
      position: relative;
      border: none;
      box-shadow: 0 7px 14px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.05);

      .editIcon {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        border-radius: 5px;
        display: flex;
        background-color: transparent;
        align-items: center;
        justify-content: center;
        transition: background-color .3s;

        svg {
          // visibility: hidden;
          margin: 5px;
          stroke: transparent;
          transition: stroke .3s;
        }
      }

      &.-active {
        .editIcon {
          border: 2px solid $secondary-color;
          box-shadow: 0 7px 14px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.05);
        }
      }

      &:hover {
        .editIcon {
          background-color: rgba($secondary-color, 0.3);

          svg {
            stroke: $white;
            visibility: visible;
          }
        }
      }
    }

    .p-dataOverview__boxHeader {
      padding: 5px;
    }

    .p-dataOverview__boxTitle {
      font-size: 12px;
    }

    .p-dataOverview__boxDescription {
      font-size: 10px;
    }

    .p-dataOverview__boxContent {
      justify-content: center;
      height: 100px;
      display: flex;
      align-items: center;

      img {
        height: 100px;
      }
    }
  }

  &__formContainer {
    width: 49%;
    position: relative;

    .p-dataOverview__boxContent {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 400px;
    }

    .p-dataOverview__boxHeader {
      padding: 10px 10px 15px;
    }

    img {
      height: 400px;
    }

    form {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .m-inputGroup, .m-selectGroup {
      margin-top: 10px;
    }

    .m-inputGroup__error {
      font-size: 11px;
      bottom: -15px;
    }

    .loader-container {
      height: 400px;
    }

    select {
      outline: none;
      padding: 0 5px;
      height: 44px;
      font-size: 13px;
      border: 1px solid #d9dfe4;
      border-radius: 6px;
      transition: border-color .3s;
      background-color: white;

      &:focus {
        outline: 1px solid $secondary-color;
      }
    }

    .selectFields {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
    }

    .selectField {
      display: flex;
      flex-direction: column;
      // margin-left: 20px;
      width: 45%;
    }
  }

}

.tooltip-header {
  font-weight: 600;
}

.customTooltip {
  z-index: 50;
}

.dxc-tooltip {
  z-index: 2;
}

.m-main.-sidenavOpen {
  td.-name {
    width: 240px;
    font-size: 12px;
  }
}