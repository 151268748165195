.m-topnav {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 135px;
  justify-content: space-between;
  background-color: $white;
  padding: 0 20px;
  border-bottom: 1px solid $gray-10;
  z-index: 22;

  &__logo {
    img {
      max-width: 160px;

      @include media-up(l) {
        padding-top: 5px;
  }
    }
  }
  @include media-up(l) {
    height: 70px;
    flex-wrap: nowrap;
  }

  &__search {
    @include media-down(m) {
      margin-left: 15px;
 
    }
  }

  &:last-child {
    width: 50%;

    @include media-up(l) {
      width: initial;
    }
  }

  .a-logo {
    width: 120px;

    @include media-up(l) {
      width: initial;
    }
  }

  div {

    &:nth-child(2) {
      order: 3;
      width: 100%;
      margin-left: 10px;

      @include media-up(l) {
        order: initial;
        width: initial;
        margin: 0;
      }
    }
  }

  .m-searchGlobal {
    margin-bottom: 15px;

    @include media-up(l) {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__saved {
    @include media-down(xs) {
      margin-left: 
      15px;
    }


    &,
    a {
      display: flex;
      align-items: center;
    }

    a {
      margin-right: 10px;

      @include media-up(l) {
        margin-right: 20px;
      }

      svg {
        transition: filter .3s;
      }

      &:hover {
        svg {
          filter: drop-shadow(0px 3px 3px rgba($secondary-color, 1));
          // fill: #00bf9c5b;
        }
      }
    }
  }

  //Where do we use this?
  // &-item {
  //   color: $white;
  //   text-align: center;
  //   padding: 15px;
  //   text-decoration: none;
  //   font-size: 17px;
  //   transition: 0.2s;

  //   &:hover {
  //     background-color: $white;
  //     color: $color-primary;
  //   }
  // }
}