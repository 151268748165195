// /////////////////////////////////////
// Adjust those styles withe design

.m-advancedFilter {
  position: relative;

  &__searchFilterContainer {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 15px;

    @include media-up(m) {
      flex-wrap: nowrap;
      justify-content: space-around;
    }

    &.box {
      box-shadow: none;
    }

    .m-advancedFilter__filterInitButton {
      &~.a-btn {
        height: 44px;
        margin-top: 15px;

        @include media-up(m) {
          position: absolute;
          right: 20px;
          margin: 0;
        }
      }
    }

    .m-modal__content {
      // overflow: hidden;
      //height: 700px;
    }
  }

  &__search {
    width: 100%;
    height: 45px;

    @include media-up(m) {
      height: 64px;
    }

    &.-error {
      & input {
        border-color: $red;

        &:focus {
          border-color: $red;
        }
      }
    }

    & input {
      height: 100%;
      width: 100%;
      outline: 0;
      padding: 0 20px;
      border: 1px solid $gray-10;
      border-radius: 5px;
      background-color: $white;
      transition: border-color .3s;

      &:focus {
        border-color: $secondary-color;
      }

      @include media-up(m) {
        padding: 0 320px 0 20px;

      }
    }
  }

  &__searchError {
    font-size: 14px;
    color: $red;
  }

  &__filterButton {
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 65px;
    margin: 5px;
    padding: 5px 20px 5px 10px;
    border-radius: 3px;
    letter-spacing: -0.2px;
    background-color: $secondary-light-color;
    color: $white;
    cursor: pointer;
    user-select: none;
    animation-name: show;
    animation-duration: .3s;
    transform: translateZ(0) translateY(0);
    transition: background-color .3s;
    z-index: 2;

    &:hover {
      background-color: darken($secondary-light-color, 10)
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      right: 6px;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      border: 3px solid $white;
      width: 5px;
      height: 5px;
      margin-top: -3px;
      border-top-color: transparent;
      border-left-color: transparent;
      transition: transform .3s, border-color .3s;
    }

    &.-open {
      z-index: 21;

      .-down & {
        .m-advancedFilter__filterButtonPopup {
          top: calc(100% + 5px);
        }
      }

      .m-advancedFilter__filterButtonPopup {
        opacity: 1;
        bottom: 40px;
        pointer-events: initial;
        min-height: max-content;

      }

      &::after {
        transform: rotate(-135deg);
      }
    }

    &.-excluded {
      background-color: $primary-light-color;

      &:hover {
        background-color: darken($primary-light-color, 10)
      }

      .m-advancedFilter {
        &__filterButtonPopup {
          background-color: $primary-light-color;
        }

        &__filterButtonKey {

          text-decoration: line-through;
        }
      }
    }

    @keyframes show {
      from {
        opacity: 0;
        transform: scale(.8);
      }

      to {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &__filterButtonClose {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%,-50%) rotate(45deg);
    width: 15px;
    height: 15px;
    background-color: $red-4;
    border: 1px solid $main-color;
    border-radius: 50%;
    
    &::before,&::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      background-color: $white;
    }

    &::before {
      width: 9px;
      height: 1px;
    }
    &::after {
      height: 9px;
       width: 1px;
    }
  }

  &__filterButtonKey {
    font-size: 12px;
    line-height: 12px;
  }

  &__filterButtonCategory {
    font-size: 10px;
    line-height: 10px;
    margin-top: 2px;
    opacity: .7;
  }

  &__filterButtonPopup {
    position: absolute;
    bottom: -20px;
    left: 50%;
    display: flex;
    flex-direction: column;
    width: max-content;
    transform: translateX(-50%);
    padding: 5px 15px;
    background-color: $secondary-light-color;
    font-size: 12px;
    letter-spacing: -0.2px;
    line-height: 18px;
    cursor: pointer;
    border-radius: 3px;
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s, bottom .3s, background-color .3s;
    z-index: 2;
    box-shadow: 0 0 5px $white;

    span {
      transform: translateX(0);
      transition: transform .3s;

      &:hover {
        transform: translateX(10px);
      }
    }

    .-down & {
      top: 0;
      bottom: auto;
      transition: opacity .3s, top .3s, background-color .3s;
    }
  }

  &__filterSave {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
  }

  &__filterInitButton {
    display: flex;
    align-items: center;
    margin-top: 15px;
    padding: 0 10px;
    height: 44px;
    background-color: white;
    border: 1px solid $secondary-color;
    border-radius: 5px;
    transition: background-color .3s, color .3s;
    cursor: pointer;
    font-size: 13px;

    @include media-up(m) {
      background-color: transparent;
      border: 0;
      margin: 0;
      position: absolute;
      right: 145px;
      font-size: 16px;
    }

    &:hover {
      background-color: $secondary-color;
      color: $white;

      svg {
        stroke: $white;
      }
    }

    svg {
      margin-right: 5px;
      transition: stroke .3s;
    }
  }

  &__stepperContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100%;
    min-height: 640px;

    @include media-up(m) {
      flex-direction: row;
    }
  }

  &__stepper {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    min-width: 206px;
    border-radius: 5px;
    padding: 40px 5px 15px;
    background-color: $gray-12;
    list-style: none;
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 40px;
    margin: 0;

    @include media-up(m) {
      display: initial;
      padding: 15px 0 15px 15px;
    }
  }

  &__stepperItem {
    cursor: pointer;
    transition: background-color .3s, color .3s;
    white-space: nowrap;
    padding: 0 10px;
    border-radius: 5px;
    margin: 0 5px;

    @include media-up(m) {
      margin: 0;
      border-radius: 5px 0 0 5px;
      padding: 0 0 0 10px;
    }

    &:hover {
      color: $primary-color;
    }

    &.active {
      color: $primary-color;
      background-color: $white;
    }
  }

  &__stepperBody {
    padding: 0 20px;
    width: 100%;
    flex: 1;
    overflow-y: auto;
    height: 650px;
    @include scrollbar;

    @include media-up(m) {
      display: flex;
      flex-direction: column;
      padding: 0 30px;
    }

    .m-tab {
      border: 1px solid transparent;
      border-bottom: 1px solid #d1d1d9;
      @include media-down(m) {
        margin-top: 20px;
      }

    }
  }

  &__stepperBodyGroup {
    width: 100%;

    h3 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      font-weight: 600;
      font-size: 20px;
      letter-spacing: -0.29px;
      line-height: 25px;
      padding-right: 20px;

      @include media-up(m) {
        white-space: nowrap;
      }

      &::after {
        content: '';
        display: none;
        height: 1px;
        background-color: $gray-10;
        margin-left: 10px;

        @include media-up(m) {
          display: block;
          width: 100%;
        }
      }
    }

    &.-box {
      border: 1px solid #d1d1d9;
      padding: 20px 0 20px 20px;
      position: relative;
      margin-top: 40px;
    }

    &.-microsoftPartner {
      .a-headingLines {
        &::before {
          display: none;
  
          @include media-up(m) {
              display: none;
          }
        }

        &::after {
          margin-bottom: 15px;
        }
      }
    }
  }

  &__stepperBodyGroupCheckboxes,
  &__stepperBodySubgroupCheckboxes {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .m-checkboxGroup {
      // margin-right: 20px;
      width: 100%;
      @include media-up(s) {
        width: 30%;
      }

      .label-logo {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
          width: 70px;
        }
      }
    }
  }

  &__stepperBodySubgroupTitle {
    margin: 0;
    position: absolute;
    top: -15px;
    left: 30px;
    font-size: 16px;
    font-weight: 600;
    background-color: $white;
    padding: 0 15px;

    img {
      width: 70px;
      margin-top: -10px;
    }
  }

  &__message {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    opacity: 0.7;
    margin: 10px 20px;
  }

  &__totalContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .total {
      font-size: 14px;
      padding-right: 15px;
      white-space: nowrap;
      position: relative;

      .loader {
        padding-right: 50px;
      }
    }
  }

  &__buttonContainer {
    display: flex;
    flex-wrap: wrap;

    @include media-up(m) {
      padding-right: 15px;

    }
  }

  // todo: remove after required class has been implemented into all form fields
  &__searchFilterContainer,
  &__buttonContainer {
    label {
      &::after {
        display: none;
      }
    }
  }
}