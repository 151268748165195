.m-savedSearchInfo {
    display: flex;
    flex-direction: column;
    background-color: $white;
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid #E5E5E9;
    border-radius: 5px;
    // width: 394px;
    height: 470px;

    @include media-up(l) {
        width: 32%;
    }
    
    &.-fullSize {
        width: 100%;
    }

    &.-shrink {
        height: 320px;

        @include media-up(s) {
            height: 470px;
        }
    }

    &__title {
        position: relative;
        z-index: 10;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.4px;
        color: $secondary-color;
        font-weight: 600;
        margin-bottom: 20px;
    }

    &__headquarters {
        width: 100%;
        height: 100%;
    }
}