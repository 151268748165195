.m-dateTimePicker {
    height: 100%;

    input:disabled {
        opacity: 0.7;
    }

    .rdt {
        height: 100%;
        border-radius: 5px;

        .form-control {
            &:focus {
                border-color: transparent;
            }
        }

        &,
        .rdtPicker {
            border: 1px solid transparent;
        }

        &.rdtOpen {

            &,
            .rdtPicker {
                border-color: $secondary-color;
            }
        }
    }

    .rdtPicker {
        width: 100%;
        margin-top: 5px;
        border-radius: 5px;

        .dow {
            font-size: 14px;
        }

        .rdtDay {
            font-size: 13px;
        }

        tr:nth-child(even) {
            background-color: transparent;
        }

        td {
            padding: 5px;
            border-radius: 5px;
        }

        .rdtActive {
            background-color: $primary-color;
        }
    }

    .rdtPrev,
    .rdtNext {
        span {
            color: $secondary-color;
        }
    }

    .rdtSwitch {
        color: #001234;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;
    }

    .rdtDays {
        tr:nth-child(odd) tr:nth-child(even) {
            background-color: $white;
        }
    }
}