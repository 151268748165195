.a-chevron {
    display: inline-block;
    border: 1.5px solid $color-primary;
    border-width: 3px;
    width: 5px;
    height: 5px;
    margin-top: -3px;
    border-top-color: transparent;
    border-left-color: transparent;
    transition: transform .3s, border-color .3s, margin-top .3s;
    cursor: pointer;

    &.-up {
        border-right-color: rgba($color-primary, .5);
        border-bottom-color: rgba($color-primary, .5);
        transform: rotate(225deg);
    }

    &.-down {
        border-right-color: $secondary-color;
        border-bottom-color: $secondary-color;
        transform: rotate(45deg);
    }

}