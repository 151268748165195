.m-fieldArray {

    &__item {
        align-items: flex-end;

        & button {
            margin-bottom: 5px;
        }
    }

    & button {
        margin-top: 30px;
    }

    &.-share {
        padding: 10px 40px;

        h4 {
            text-align: center;
        }

        .a-btn {
            width: 100%;
        }
    }

    &__itemContainer {
        display: flex;
        align-items: flex-end;

        .m-inputGroup {
            width: 100%;
        }

        .remove {
            margin-bottom: 5px;
            margin-left: 20px;
            cursor: pointer;
        }

    }

    &__add {
        background-color: $base-color;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        padding: 8px;
        margin-top: 30px;
        cursor: pointer;
        transition: background-color .3s;

        &:hover {
            background-color: #dcdcdc;
            transition: background-color .3s;
        }
    }
}