.dataView__checkboxGroup {
    background-color: $white;
    margin:20px 0;
    padding: 10px;
    height: auto;
    border:1px solid #dbd7d7;
    padding: 20px;
    display: flex;
    align-items: center;

    @include media-down(l) {
        width:100%;
    }
}