.m-technologyTrends {
    @include animation-fade-in;
    margin-top: 40px;

    &__resultsView {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__results {
        margin-right: 30px;
    }

    &__resultsDisplaying {
        font-size: 24px;
        letter-spacing: -0.3px;
        line-height: 24px;
        font-weight: 600;
    }

    &__resultsShowing {
        display: inline-block;
        margin-top: 10px;
        opacity: 0.5;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.17px;
        line-height: 24px;
    }

    &__view {
        display: flex;
        align-items: center;
    }

    &__viewChange {
        font-size: 13px;
        letter-spacing: -0.2px;
        line-height: 16px;
    }

    &__viewSelect {
        display: flex;
        align-items: center;
        height: 44px;
        width: 188px;
        margin-left: 10px;
        border: 1px solid $gray-10;
        border-radius: 6px;
        background-color: $white;
        font-size: 13px;
        letter-spacing: -0.2px;
        line-height: 16px;
        padding: 13px;
    }

    &__tableDiv {
        overflow-x: auto;
        padding-left: 1px;
        @include scrollbar;

        @include media-up(l) {
            overflow: visible;
        }
    }

    &__table {
        width: 100%;
        margin-top: 47px;

        th {
            border: 0;
        }

        tbody {
            background-color: $white;
        }

        tr {
            background-color: transparent;
        }

        td {
            &:first-child {
                padding: 13px;
                font-size: 13px;
                letter-spacing: -0.2px;
                line-height: 20px;
                text-align: left;
                white-space: nowrap;
            }

            border: 1px solid $gray-11;

            &.-red {
                &::after {
                    border: 1px solid rgba(228, 36, 52, 0.1);
                    background-color: rgba(228, 36, 52, 0.1);
                }
            }

            &.-green {
                &::after {
                    border: 1px solid rgba(6, 190, 74, 0.3);
                    background-color: rgba(6, 190, 74, 0.2);
                }
            }

            &.-green,
            &.-red {
                position: relative;

                &::after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: calc(100% - 5px);
                    height: calc(100% - 5px);
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    border-radius: 3px;
                }
            }
        }

    }

    &__thContent {
        display: flex;
        align-items: center;
        margin: 0 auto;
        width: max-content;
    }

    &__namePartner {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 10px;

        span {
            color: $blue-4;
            font-size: 12px;
            letter-spacing: -0.15px;
            line-height: 15px;

            &.-companyName {
                font-weight: 600;

            }

            &.-partner {
                opacity: 0.5;

                font-weight: 400;
            }
        }

    }

}