.m-subsidiaries {
    margin-top: 40px;

    .m-tab {
        &.-subcards {
            border-bottom: 3px solid $primary-color;
            border-radius: 0;
        }

        .m-tab__tab {
            &.-active {
                &::after {
                    background-color: transparent;
                }
            }
        }
    }

    .m-searchGlobal {

        &.-tableSearch {
            width: 100%;

            .m-searchGlobal__input {
                width: 100%;
            }
        }
    }

    &__new {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        .m-selectGroup {
            width: 70%;
        }
    }
}