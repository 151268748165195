.m-searchFilters {

    &__list {
      margin-right: 10px;

      :not(:first-child):before {
          content: ", ";
        }
    }

    &__viewAll {
      color: #4184FC;
      cursor: pointer;
    }
}