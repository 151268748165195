.m-benefits {
    // @include media-up(xl) {
    //     margin-left: 20px;
    // }

    // @include media-up(xxl) {
    //     margin-left: 85px;
    //     padding-left: 0;
    // }

    &__content {
        justify-content: space-between;
        padding: 0 15px;

        @include media-up(m) {
            padding: 0;

        }

        // @include media-up(xl) {
        //     padding: 0;
        // }

        &--left {
            padding: 80px 0;
            text-align: center;

            @include media-up(m) {
                margin-left: 100/1440*100%;
            }

            @include media-up(xl) {
                text-align: initial;
                padding-bottom: 0;
            }

            @include media-up(xxl) {
                flex: 0 0 19%;
                max-width: 19%;
            }

            h2 {
                letter-spacing: -1px;
                line-height: 70px;

                @include media-up(xl) {
                    font-size: 52px;
                }
            }

            .-blueLine {
                margin: 25px 0;

                @include media-up(xl) {
                    margin: 31px 0 25px;
                }
            }

            p {
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0;
                line-height: 30px;
                color: $gray-8;
            }
        }

        &--right {
            @include media-up(l) {
                height: 770px;
                overflow-y: scroll;
                overflow-x: hidden;
                padding-right: 0;
            }

            @include media-up(xxl) {
                flex: 0 0 64.7%;
                max-width: 64.7%;
            }

            &::-webkit-scrollbar {
                width: 0;
                background: transparent;
            }

            .flex {
                display: flex;
                position: relative;
                flex-direction: column;

                @include media-up(m) {
                    flex-direction: row;
                }

                .text-content {
                    flex: 0 0 100%;
                    max-width: 100%;
                    position: relative;

                    @include media-up(m) {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }

                    @include media-up(xl) {
                        padding-top: 80px;
                        flex: 0 0 43.7%;
                        max-width: 43.7%;
                    }

                    div {
                        padding: 0 0 15px 0;

                        margin-bottom: 50px;

                        @include media-up(m) {
                            margin-bottom: 44px;
                        }

                        p {
                            letter-spacing: 0;
                            line-height: 26px;
                            color: $gray-8;

                            &:nth-of-type(1) {
                                color: $blue-4;
                                opacity: 0.5;
                                font-size: 20px;
                                position: relative;
                                margin-bottom: 12px;

                                &::after {
                                    content: "";
                                    position: absolute;
                                    width: 100%;
                                    height: 1px;
                                    left: 35px;
                                    top: 12px;
                                    background: linear-gradient(270deg, #ffffff 0%, #c5c5cc 100%);
                                }
                            }

                            &:nth-of-type(2) {
                                margin-bottom: 10px;
                            }

                            &:nth-of-type(3) {
                                margin-bottom: 15px;
                            }
                        }

                        .bold {
                            color: $color-primary;
                            font-weight: 600;

                            span {
                                position: relative;

                                &::after {
                                    content: "";
                                    position: absolute;
                                    left: 10px;
                                    top: 11px;
                                    background-color: $secondary-color;
                                    width: 20px;
                                    height: 2px;
                                }
                            }
                        }

                        h3 {
                            letter-spacing: -1px;
                            line-height: 40px;
                            margin-bottom: 30px;
                            padding-right: 20px;
                        }
                    }
                }

                .image-content {
                    position: relative;
                    flex: 0 0 100%;
                    max-width: 100%;

                    @include media-up(m) {
                        flex: 0 0 54%;
                        max-width: 54%;
                        margin-left: auto;
                    }

                    &__top {
                        background-image: url(../../images/b.jpg);
                    }

                    &__bottom {
                        background-image: url(../../images/people.jpg);

                    }

                    div {
                        height: 300px;
                        background-size: cover;
                        background-position: center;
                        margin-bottom: 14px;

                        @include media-up(m) {
                            height: 50%;
                            margin-bottom: 0;
                        }

                        @include media-up(xl) {
                            height: 50%;

                        }

                        @include media-up(xxl) {
                            height: 50%;
                        }
                    }

                    .scroll-progress {
                        position: absolute;
                        top: 0;
                        left: 0;
                        min-height: 20%;
                        background-color: $secondary-color;
                        transition: all linear 0.1s;
                        width: 4px;
                        display: none;

                        @include media-up(l) {
                            display: block;
                        }
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 4px;
                        height: 100%;
                        background-color: rgba($secondary-color, 0.15);
                        display: none;

                        @include media-up(l) {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}