.m-modalSearchFilters {
  .m-modal__header {
    background-color: white;
    padding-bottom: 20px;
  }

  .m-modal__content {
    height: 700px;
  }
}

.m-modal.-show.__search {
  .m-modal__content {
    padding-top: 50px;
  }

  .m-modal__close {
    z-index: 3;
    top: 18px;
  }

  .edit-search-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    position: absolute;
    top: 0;
    width: 100%;
    padding-top: 10px;
    background: $gray-12;
    padding-bottom: 10px;
    z-index: 1;

    .label {
      padding-right: 10px;
      text-align: right;
      line-height: 45px;
      padding-bottom: 10px;
    }

    span {
      width: 50%;
      height: 45px;
    }

    .m-inputGroup {
      height: 45px;
      margin-top: 0px;
      width: 200px;
      padding-bottom: 10px;
    }

  .m-inputGroup__error {
      bottom: -15px;
      left: 205px;
    }
  }


  //.m-inputGroup {
  //  position: absolute;
  //  top: -20px;
  //  width: calc(100% - 50px);
  //  left: 0;
  //}
}