.m-upgradeToPremium {
    background-color: #f58e22;
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    width: fit-content;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 17;
    transform: translate(-50%, -50%);
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 2px;
    color: $white;
    transition: background-color .3s;
    box-shadow: 0 4px 8px 0 rgba($black, 0.2), 0 6px 20px 0 rgba($black, 0.19);

    &:hover {
        background-color: #001a6f;
    }
}