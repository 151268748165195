.m-dealProcessBlock {
    margin: 0 5px 20px;
    position: relative;
    min-width: calc(100%/6 - 10px);

    &.-item9,
    &.-item10,
    &.-item11,
    &.-item12,
    &.-item13,
    &.-item14 {
        display: block;
        flex: 1;
    }

    &.-item15,
    &.-item16 {
        display: none;

        @media (max-width: 1260px) {
            display: block;
        }
    }


    &__header {
        padding: 7px 7px 7px 15px;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.3px;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 5px;
        margin-bottom: 5px;

        &.-item9 {
            background-color: $purple-1;
        }

        &.-item10 {
            background-color: $blue-6;
        }

        &.-item11 {
            background-color: $orange-1;
        }

        &.-item12 {
            background-color: $brown-1;
        }

        &.-item13 {
            background-color: $green-2;
        }

        &.-item14 {
            background-color: $green-3;
        }

        &.-item15 {
            background-color: $red-2;
        }

        &.-item16 {
            background-color: #282a2f;
        }

    }


    &__count {
        background-color: rgba($white, 0.2);
        border: 1px solid rgba($white, 0.15);
        border-radius: 8px;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__container {
        height: 100%;
        width: 100%;
        // overflow-y: auto;
        padding-right: 12px;

        /* width */
        &::-webkit-scrollbar {
            border-radius: 1.5px;
            margin: 15px 5px;
            width: 2px;

        }

        /* Track */
        &::-webkit-scrollbar-track {
            background-color: rgba($blue-4, .2);
            border-radius: 1.5px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            width: 2px;
            border-radius: 1.5px;
            background-color: $blue-4;
            border-radius: 1.5px;

        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    &__statusList {
        min-height: 305px;
        max-height: 305px;
        border-radius: 5px;
        border: 1px solid;
        padding: 15px 6px 15px 19px;
        overflow-y: auto;

        &.-active {
            border-style: dashed;
        }

        &.-item9 {
            background-color: #eae5f6;
            border-color: #d2c2ea;

            &.-active {
                border-color: $purple-1;
            }
        }

        &.-item10 {
            background-color: #dfedfa;
            border-color: #b4d8f5;

            &.-active {
                border-color: $blue-6;
            }
        }

        &.-item11 {
            background-color: #f6efe9;
            border-color: #f5ddc6;

            &.-active {
                border-color: $orange-1;
            }
        }

        &.-item12 {
            background-color: #eeeced;
            border-color: #dfd4d1;

            &.-active {
                border-color: $brown-1;
            }
        }

        &.-item13 {
            background-color: #e2f3f6;
            border-color: #BDe8e9;

            &.-active {
                border-color: $green-2;
            }
        }

        &.-item14 {
            background-color: #e5f4ee;
            border-color: #c5ebd5;

            &.-active {
                border-color: $gray-3;
            }
        }

        &.-item15 {
            background-color: #f4dfe2;
            border-color: rgba(217,76,76,0.2);

            &.-active {
                border-color: $red-2;
            }
        }

        &.-item16 {
            background-color: #DFE0E1;
            border-color: #9C9DA0;

            &.-active {
                border-color: #282a2f;
            }
        }
    }


    &__statusItem {
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        letter-spacing: -0.15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        outline: 0;

        &:not(:first-child) {
            margin-top: 8px;
        }

        &.-active {
            background-color: $white;
            border: 1px dashed $blue-1;
            border-radius: 5px;
            box-shadow: 0 10px 20px -3px rgba($blue-1, 0.2);
            min-width: fit-content;
        }

        img {
            width: 20px;
            height: 20px;
            background-color: $black;
            margin-right: 7px;
            border-radius: 3px;
        }

        .image {
            width: 20px;
            height: 20px;
            background-color: rgba(223, 223, 223, 0.5);
            margin-right: 7px;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:hover {
            .m-dealProcessBlock__statusItemTooltip { 
                display: flex !important;
            }
        }
    }

    &__statusItemContainer {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__statusItemLocation {
        opacity: 0.5;
    }

    &__statusItemTooltip {
        position: absolute;
        // max-width: 175px;
        // left: 12px;
        // bottom: 30px;
        width: 100%;
        left: 0;
        bottom: -50px;
        padding: 5px 8px !important;
        display: none !important;
    }

    &__moveToPlaceholder, &__statusItemTooltip {
        background-color: rgba($white, 0.5);
        border: 1px dashed;
        border-radius: 5px;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: -0.2px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        padding: 9px;
        animation-name: show;
        animation-duration: .3s;


        @keyframes show {
            from {
                transform: scale(0);
            }

            to {
                transform: scale(1);
            }
        }



        &.-item9 {
            border-color: $purple-1;
        }

        &.-item10 {
            border-color: $blue-6;
        }

        &.-item11 {
            border-color: $orange-1;
        }

        &.-item12 {
            border-color: $brown-1;
        }

        &.-item13 {
            border-color: $green-2;
        }

        &.-item14 {
            border-color: $green-3;
        }

        &.-item15 {
            border-color: $red-2;
        }

        &.-item16 {
            border-color: #282a2f;
        }
    }

}