.flex {
  display: flex;
}

._aic {
  align-items: center;
}

._jcfe {
  justify-content: flex-end;
}

._jcfs {
  justify-content: flex-start;
}

._jcsb {
  justify-content: space-between;
}
