.m-sidebar {
  position: fixed;
  right: 0;
  height: calc(100% - 70px);
  top: 120px;
  width: 100%;


  @media (min-width: 992px) {
    top: 70px;
  }

  @include media-up(m) {
    width: 1040px;
    max-width: 90%;
  }

  &__inner {
    position: relative;
    z-index: 20;
    width: 100%;
    height: 100%;
    padding: 50px;
    // padding-top: 80px;
    overflow-y: auto;
    background-color: $main-color;
    @include scrollbar;

    // .m-sidebar__close {
    //   top: 85px;
    // }
  }

  &__overlay {
    position: absolute;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    z-index: 15;
    background-color: rgba(56, 63, 82, .8);
  }

  &__close {
    position: absolute;
    top: 50px;
    right: 35px;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &:hover {

      &::before,
      &::after {
        background-color: rgba($black, .5);
      }
    }

    &::before,
    &::after {
      position: absolute;
      display: block;
      left: 0;
      top: 50%;
      width: 100%;
      height: 1.5px;
      content: '';
      transition: background-color .3s;
      background-color: rgba($black, .3);
    }

    &::before {
      transform: translateY(-50%) rotate(45deg);
    }

    &::after {
      transform: translateY(-50%) rotate(-45deg);
    }
  }
}