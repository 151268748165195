.m-sideModal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 10;
    right: 0;
    top: 70px;
    width: 100%;
    height: 100%;
    padding: 15px;
    background-color: rgba($white, 0.7);
    border-left: 1px solid $gray-10;
    backdrop-filter: blur(2px);
    transition: transform .3s;
    animation-name: slidIn;
    animation-duration: .3s;

    @include media-up(m) {
        width: unset;
        max-width: calc(100vw - 63px);
        padding: 60px;
    }

    @keyframes slidIn {
        from {
            transform: translateX(100%);
        }
        to {
            transform: translateX(0);
        }
    }

    &__body {
        position: relative;
        background-color: $color-primary;
        border-radius: 10px;
        color: $white;
        padding: 37px 25px;
        width: 374px;

        @include media-up(s) {
            padding: 37px 40px;

        }

        .m-modal__close {

            &::before,
            &::after {
                background-color: $white;
                width: 80%;
            }
        }

        h4 {
            text-align: center;
            font-size: 22px;
            letter-spacing: -0.5px;
            line-height: calc(36/26);
            text-align: center;
            font-weight: 600;

            @include media-up(m) {
                font-size: 26px;

            }
        }

        form {
            display: flex;
            flex-direction: column;
            align-items: center;

            button {
                margin-top: 25px;
            }
        }

        &.-notInteresting {
            .m-radioGroup {
                .label {
                    color: $white;
                }
            }
        
            .m-textareaGroup {
                height: 130px;
            }
        }
    }

    .m-radioGroupContainer {
        width: 100%;
        margin-top: 5px;

        label {
            margin: 0;
        }

        &__options {
            flex-direction: column;
        }
    }

    .m-radioGroup {
        margin-top: 15px;

        .radio {
            box-shadow: none;
            background-color: rgba($white, 0.07);
            border-color: rgba($white, .2);
            margin-right: 10px;
        }

        input:checked+label {
            .radio {
                background-color: rgba($white, 0.1);
                box-shadow: none;
                border-color: $white;

                &::after {
                    background-color: $white;
                    transform: scale(1);
                }
            }
        }
    }

    .m-textareaGroup {
        height: 80px;

        textarea {

            margin-top: 10px;
            background-color: rgba($white, 0.07);
            border-color: rgba($white, .2);
            color: $white;

            &::placeholder {
                color: rgba($white, .7)
            }
        }
    }

    .a-btn {
        padding: 16px 37px;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 19px;
    }

}