.p-premium {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    &__continue {
        .a-btn {
            width: 240px;

            &.-primary {
                padding: 0 5px;
            }
        }
    }

    .m-inputGroup  {
        label {
            color: $white;
            font-weight: 600;
        }

        &.-error {
            input {
                border-color: #ff0800;
            }
        }

        &__error {
            color: #ff0800;
        }
    }

    h3 {
        max-width: 75%;
        font-size: 20px;
        font-weight: 300;
    }

    .m-noAuth__topText {
        background-color: rgba($black, .3);
        padding: 20px 10px;
        max-width: 900px;
        border-radius: 5px;
    }

}