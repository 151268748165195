.dataView {
    &__modeIcon {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;

        svg {
            cursor: pointer;
            opacity: 0.5;

            &:hover {
                opacity: 0.7;
            }
        }
    }

    &__boxContainer {
        margin: 20px 0;
    }

    &__boxItem {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        background-color: $white;
        border: 1px solid #D1D1D9;
        padding: 20px;
        border-radius: 5px;
        cursor: pointer;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);

        &:hover {
            box-shadow: 0 7px 14px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.05);
        }

        &.-empty {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 250px;
            border: 1px dashed rgba($secondary-color, 0.5);
            background-color: $white;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);

            &:hover {
                border: 1px dashed rgba($secondary-color, 1);
                box-shadow: 0 7px 14px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.05);
            }

            .new {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
            }

            svg {
                margin-bottom: 10px;
            }
        }

        .header {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 15px;

            .titleAndDate {
                display: flex;
                flex-direction: column;
            }

            .title {
                font-size: 20px;
                line-height: 27px;
                color: #001234;
                font-weight: 600;
                letter-spacing: -0.23px;
            }

            .sharedBy {
                font-size: 12px;
                font-style: italic;
            }

            .iconsBox {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                justify-content: flex-end;
            }

            .date {
                opacity: 0.5;
                color: #001234;
                font-size: 14px;
                font-style: oblique;
                font-weight: 400;
                letter-spacing: -0.28px;
                line-height: 19px;
                margin-top: 2px;
            }

            .iconContainer {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 32px;
                width: 32px;
                border-radius: 5px;
                background-color: $base-color;
                cursor: pointer;
                margin-left: 5px;
                margin-bottom: 3px;
            }
        }

        .m-searchFilters__list {
            color: #001234;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: -0.18px;
            line-height: 22px;
        }

        .list {
            display: flex;
            flex-wrap: wrap;

            .image-name {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                cursor: pointer;
                margin: 5px 10px 5px 0;
                text-align: left;

                img, span {
                    min-width: 34px;
                    min-height: 34px;
                    width: 34px;
                    height: 34px;
                    border-radius: 3px;
                    margin-right: 10px;
                }

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba(223, 223, 223, 0.5);
                }
            }
        }

    }

    &__list {
        display: flex;
        flex-direction: column;
        overflow-x: auto;
    }

    &__listItem {
        width: 100%;
        margin-bottom: 10px;
        cursor: pointer;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);

        &:hover {
            box-shadow: 0 7px 14px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.05);
        }

        &.-empty {
            background-color: $white;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);

            &:hover {
                box-shadow: 0 7px 14px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.05);
                
                td {
                    border: 1px dashed rgba($secondary-color, 1);
                }
            }

            td {
                border: 1px dashed rgba($secondary-color, 0.5);
            }

            .new {
                display: flex;
                align-items: center;
                justify-content: center;
            }

            svg {
                margin-right: 10px;
            }
        }

        .date {
            opacity: 0.5;
            color: #001234;
            font-size: 14px;
            font-style: oblique;
            font-weight: 400;
            letter-spacing: -0.28px;
            line-height: 19px;
            margin-right: 15px;
        }

        .titleAndDate {
            display: flex;
            flex-direction: column;
        }

        .title {
            font-size: 16px;
            line-height: 27px;
            color: #001234;
            font-weight: 600;
            letter-spacing: -0.23px;
        }

        .sharedBy {
            font-size: 12px;
            font-style: italic;
        }

        .iconContainer {
            cursor: pointer;
            margin-left: 5px;
        }
    }

    &__table {
        font-size: 15px;
        border-collapse: separate;
        table-layout: fixed;
        width: 100%;
        border-spacing: 0 5px;
        min-width: 765px;

        th {
            text-align: left;
            padding: 5px 15px;
            border-color: transparent;
            color: #001234;
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 19px;
        }

        td {
            text-align: left;
            border-width: 1px 0;
            // width: 50%;
            border-color: #D1D1D9;
            border-style: solid;
            background-color: $white;
            color: $black;
            padding: 20px;
            border-right-width: 1px;
            border-right-color: #d1d1d97e;
            color: #001234;
            font-size: 13px;
            font-weight: 400;
            letter-spacing: -0.15px;
            line-height: 18px;
        }

        td:first-child {
            border-left-width: 1px;
            border-radius: 5px 0 0 5px;
        }

        td:last-child {
            border-right-width: 1px;
            border-radius: 0 5px 5px 0;
        }

        td.action, th.action {
            width: 130px;
        }
        td.action {
            text-align: center;
        }

        thead {
            display: table;
            table-layout: fixed;
            width: 100%;
        }

        tbody {
            display: table;
            table-layout: fixed;
            width: 100%;
            border-spacing: 0 10px;
        }
    }

    &__platformContainer {
        margin-top: 40px;
    }

    &__platformCard {
        margin-bottom: 40px;
        &:hover {
            .dataView__platform {
                box-shadow: 0 7px 14px rgba($black, 0.1), 0 10px 10px rgba($black, 0.05);
            }
        }

        .title {
            font-size: 18px;
            letter-spacing: 0;
            line-height: 30px;
            font-weight: 600;
        }

        .description {
            color: #0012347e;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: -0.15px;
            line-height: 18px;
            margin-left: 5px;
        }

        &.-userProfile {
            margin-top: 20px;
            padding-left: 0;
            pointer-events: none;
        }
    }

    &__platform {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: $white;
        border: 1px solid #D1D1D9;
        padding: 20px;
        border-radius: 5px;
        // margin: 10px 0;
        // height: 100%;
        cursor: pointer;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
        align-items: center;
        text-align: center;

        &:hover {
            box-shadow: 0 7px 14px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.05);
        }

        .name {
            font-size: 20px;
            line-height: 27px;
            color: #001234;
            font-weight: 600;
            letter-spacing: -0.23px;
        }

        .platform {
            color: #0012347e;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: -0.15px;
            line-height: 18px;
            margin-bottom: 10px;
        }

        .description {
            font-size: 14px;
            color: #001234cb;
            line-height: 18px;
        }

        .countries {
            display: flex;
            align-items: center;
        }

        .flag {
            width: 25px;
            margin: 5px;
        }
    }

    &__platformResults {
        margin-right: 0px;
        width: 100%;
        margin-top: 40px;

        @include media-up(m) {
            width: 70%;
            margin-right: 30px;
        }
    }

    &__platformResultsDisplaying {
        font-size: 24px;
        letter-spacing: -0.3px;
        line-height: 24px;
        font-weight: 600;
    }

    &__platformResultsShowing {
        display: inline-block;
        margin-top: 10px;
        margin-bottom: 10px;
        opacity: 0.5;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.17px;
        line-height: 24px;
    }

    .line {
        height: 1px;
        width: 100%;
        background-color: #D1D1D9;
        opacity: 0.5;
        margin: 20px 0;
    }

    .subtitle {
        color: #0012347e;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: -0.15px;
        line-height: 18px;
        margin-bottom: 5px;
    }

    .count {
        margin-left: 5px;
        color: #001234;
        font-size: 14px;
    }

    &__subgroups {
        display: flex;
        flex-direction: column;
        padding-left: 100px;
        padding-right: 30px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 50px;
            height: calc(100% - 30px);
            width: 1px;
            border: 1px dashed rgba(180,185,196,0.5);
        }

        .title {
            font-size: 18px;
            letter-spacing: 0;
            line-height: 30px;
            font-weight: 600;
            margin-top: 20px;
        }
    }

    &__subgorupCard {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $white;
        border: 1px solid #D1D1D9;
        border-radius: 5px;
        padding: 15px 20px;
        margin-top: 10px;
        cursor: pointer;
        transition: background-color .3s, border-color .3s;
        position: relative;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);

        &:hover {
            border: 1px solid $secondary-color;
            box-shadow: 0 7px 14px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.05);
        }

        &::before {
            content: '';
            position: absolute;
            height: 1px;
            width: 50px;
            left: -50px;
            border: 1px dashed rgba(180,185,196,0.5);
        }

        &::after {
            content: '';
            position: absolute;
            height: 8px;
            width: 8px;
            border-radius: 100px 0 0 100px;
            background-color: $secondary-color;
            left: -5px;
        }

        &.-noItems {
            cursor: default;
            background-color: rgba(#ffffff, 0.5);
            border: 1px solid #D1D1D9;
            color: rgba(#000000, 0.5);

            &:hover {
                background-color: rgba(#ffffff, 0.5);
                border: 1px solid #D1D1D9;
            }

            &::after {
                display: none;
            }
        }

        .name {
            font-size: 14px;
            line-height: 24px;
        }

        .image {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(223, 223, 223, 0.5);
            width: 24px;
            height: 24px;
            border-radius: 5px;
        }

        img {
            // height: 40px;
            width: 75px;
            max-width: none;
        }

        .icons {
            display: flex;
            align-items: center;
        }

        .flag {
            width: 20px;
            margin-left: 10px;
        }
    }

    .m-checkboxGroupContainer {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        background-color: $white;
        margin: 20px 0;
        padding: 0 20px;
        border:1px solid #dbd7d7;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);

        .groupLabel {
            font-size: 16px;
            font-weight: bold;
            margin-right: 20px;
            padding: 20px;
        }

        .m-checkboxGroup {
            margin: 0;
            padding: 20px;
            label {
                font-size: 14px;
                font-weight: bold;
            }                    
        }        
    }

    .counterContainer {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

}