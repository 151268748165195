.m-selectOptgroup {
  display: flex;
  flex-direction: column;

  select {
    height: 44px;
    padding: 0 15px;
    border-radius: 5px;
    font-size: 13px;
    border: 1px solid $gray-11;
    background-color: $white;
    appearance:none;
    cursor: text;

    &:focus {
      outline: none;
      border-color: $color-info;
    }
  }
}