.notification {
  position: absolute;
  right: 10px;
  top: 20px;
  padding: 20px;
  color: white;
  opacity: 1;
  transition: opacity 0.6s;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-info {
    background-color: $color-info;
  }

  &-success {
    background-color: $color-success;
  }

  &-warning {
    background-color: $color-warning;
  }

  &-error {
    background-color: $color-error;
  }
}
