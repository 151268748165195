.m-footer {
    background-image: url(../../images/header.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    background-color: $color-primary;
    // background-color: rgba($black, .9);

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.7);
    }

    &__contentTop {
        padding: 50px 0;
        justify-content: center;

        @include media-up(xl) {
            justify-content: space-between;
        }

        &--left {
            text-align: center;

            @include media-up(xl) {
                text-align: initial;
            }

            @include media-up(xxl) {
                margin-right: 75px;
            }

            .a-logo {
                filter: brightness(0) invert(1);
                margin: 0 auto 30px;

                @include media-up(l) {
                    margin: 0;
                }
            }

            p {
                font-weight: 600;
                color: $white;
                margin-bottom: 0;
            }

            h2 {
                margin-top: 10px;
                letter-spacing: -1.31px;
                line-height: 52px;
                color: $white;
            }

            .flex {
                display: flex;
                align-items: center;
                margin-top: 30px;
                position: relative;
                flex-direction: column;

                @include media-up(m) {
                    flex-direction: row;
                }

                .btn-and-input {
                    position: relative;
                    width: 100%;
                    margin-bottom: 20px;

                    .m-inputGroup {
                        position: relative;
                        margin-top: 0;
                        height: 60px;
                    }

                    label {
                        pointer-events: none;
                        position: absolute;
                        top: -20px;
                        left: 20px;
                        opacity: 0;
                        transform: translateY(20px);
                        transition: opacity .3s, transform .3s;
                        background-color: transparent;
                        color: $white;
                        padding: 0;
                        font-size: 12px;
                    }

                    .m-inputGroup__error {
                        position: absolute;
                        bottom: -25px;
                        left: 20px;
                        color: $red;
                        opacity: 0;
                        transition: opacity .3s;

                        &.-active {
                            opacity: 1;
                        }
                    }

                    .a-input {
                        background-color: transparent;

                        &:focus {

                            +label {
                                opacity: 1;
                                transform: translateY(0);
                            }
                        }
                    }

                    @include media-up(m) {
                        margin-right: 30px;
                        margin-bottom: 0;
                    }

                    .-transparent {
                        position: relative;
                        padding: 17px 150px 17px 20px;
                    }

                    .-primary {
                        position: absolute;

                        right: 7px;
                        top: 50%;
                        transform: translateY(-50%);
                        padding: 10px;

                        @include media-up(m) {
                            padding: 14px 18px;
                        }
                    }
                }

                .social-icons {
                    display: flex;

                    a {
                        &:not(:last-child) {
                            margin-right: 10px;
                        }

                        img {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }

        &--links {
            margin-top: 40px;
            text-align: center;
            padding: 0;

            @include media-up(xl) {
                text-align: center;

            }

            div {
                max-width: max-content;
                min-width: 200px;
                margin: 0 auto;

                @include media-up(xl) {
                    max-width: none;
                    margin: 0;
                }

                a {
                    padding: 0 15px
                }

            }


            @include media-up(xl) {
                // margin-top: 25px;
                text-align: initial;
            }

            p {
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 2px;
                line-height: 19px;
                color: $white;
                opacity: 0.5;
                text-transform: uppercase;
                margin-bottom: 10px;

                @include media-up(xl) {
                    margin-bottom: 25px;
                }
            }

            a {
                display: block;
                padding-bottom: 3px;
                letter-spacing: 0;
                transition: ease-in-out 0.2s;
                cursor: pointer;
                color: $white;

                &:hover {
                    color: $orange-1;
                }

                &.-arrowRight {
                    &::before {
                        top: 9px;
                        left: 0;
                    }
                }
            }
        }
    }

    hr {
        border: 0;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            background-color: rgba($white, 0.2);
            width: calc(100% + 120px);
            height: 2px;
        }
    }

    &__contentBottom {
        padding: 15px;
        justify-content: center;

        @include media-up(m) {
            justify-content: space-between;
        }

        p,
        a {
            letter-spacing: 0;
            line-height: 30px;
            color: $white;
            opacity: 0.5;
            margin-bottom: 0;
        }

        div {
            display: flex;

            a {
                &:first-child {
                    margin-right: 15px;
                }
            }
        }
    }
}