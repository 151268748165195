.m-inputGroup {
    position: relative;
    width: 100%;
    text-align: left;

    &.-password {
        input {
            padding-left: 59px;

            &[type="password"] {
                letter-spacing: 4px;

                &::placeholder {
                    letter-spacing: initial;
                }
            }
        }
    }

    &.-error {
        input {
            border-color: $red;
        }
    }

    &.-hasValue {

        input {
            background-color: $white;
        }
    }

    &.-disabled {
        opacity: 0.7;

        label {
            color: #828a8d;
        }
    }

    input {
        width: 100%;
        height: 44px;
        padding: 0 15px 0 15px;
        border-radius: 6px;
        border: 1px solid $gray-11;
        background-color: $white;
        outline: 0;
        color: $blue-5;
        font-size: 13px;
        transition: border-color .3s, background-color .3s;
        filter: none;

        &:invalid {
            box-shadow: none;
        }

        &:focus {
            background-color: $white;
            border-color: $secondary-color;

            ~svg {
                stroke: $secondary-color;
            }
        }

        &::placeholder {
            opacity: 1;
            color: $gray-6;
        }
    }

    &.-required {
        label {
            &::after {
                position: relative;
                content: '*';
                color: $red;
                right: -5px;
            }
        }
    }

    label {
        font-size: 13px;
        line-height: 16px;
    }

    svg {
        position: absolute;
        right: 10px;
        bottom: 10px;
        transition: stroke .3s;
    }

    &__error {
        position: absolute;
        bottom: -20px;
        left: 0;
        color: $red;
        opacity: 0;
        font-size: 13px;
        transition: opacity .3s;
        letter-spacing: -0.2px;
        line-height: 16px;
        white-space: nowrap;

        &.-active {
            opacity: 1;
        }
    }
}