.pagination-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 20px;

  @include media-up(l) {
    flex-direction: row;
    align-items: center;
  }

  .pagination-selector {
    display: flex;
    font-size: 14px;
    line-height: 19px;
    color: $blue-5;
  }
}

.pagination {
  display: inline-flex;
  align-items: center;
  padding: 4px;
  background-color: $white;
  border: 1px solid $gray-10;
  border-radius: 5px;
  margin-top: 10px;

  @include media-up(l) {
    margin-top: 0;
  }

  .item {
    cursor: pointer;
    color: $black;
    min-width: 36px;
    height: 36px;
    display: flex;
    padding: 0 5px;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    text-decoration: none;
    user-select: none;
    transition: background-color 0.3s;
    font-size: 14px;
    line-height: 19px;

    &:hover {
      background-color: $gray-200;
    }

    &.active {
      background-color: $secondary-color;
      color: $white;
    }

    &.disabled {
      pointer-events: none;
      color: $gray-10;
    }
  }
}