// Links
//
// Style anchor elements.

$link-color: $color-info !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15) !default;
$link-hover-decoration: underline !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 0 !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: -apple-system,
BlinkMacSystemFont,
sans-serif !default;
$font-family-monospace: SFMono-Regular,
Menlo,
Monaco,
Consolas,
monospace !default;
$font-family-base: $font-family-sans-serif !default;
$font-avenir: "Avenir",
$font-family-sans-serif;
$font-gilroy: "Gilroy",
$font-family-sans-serif;
$font-sf-pro: "SF Pro Display",
$font-family-sans-serif;
$font-barlow: "Barlow",
$font-family-sans-serif;
// stylelint-enable value-keyword-case

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base * 1.25) !default;
$font-size-sm: ($font-size-base * 0.875) !default;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;

$font-weight-base: $font-weight-normal !default;

$color-text-base: $gray-9;
$line-height-base: 1.6 !default;

$h1-font-size-mobile: 38px !default;
$h2-font-size-mobile: 34px !default;
$h3-font-size-mobile: 24px !default;
$h4-font-size-mobile: 20px !default;
$h5-font-size-mobile: 16px !default;
$h6-font-size-mobile: 13px !default;

$h1-font-size-tablet: 38px !default;
$h2-font-size-tablet: 34px !default;
$h3-font-size-tablet: 24px !default;
$h4-font-size-tablet: 20px !default;
$h5-font-size-tablet: 16px !default;
$h6-font-size-tablet: 13px !default;

$h1-font-size-desktop: 48px !default;
$h2-font-size-desktop: 42px !default;
$h3-font-size-desktop: 30px !default;
$h4-font-size-desktop: 25px !default;
$h5-font-size-desktop: 20px !default;
$h6-font-size-desktop: 16px !default;
$headings-margin-bottom: 0 !default;
$headings-font-family: $font-gilroy;
$headings-font-weight: 600 !default;
$headings-line-height: 1.2 !default;
$headings-color: inherit !default;

$lead-font-size: ($font-size-base * 1.25) !default;
$lead-font-weight: 400 !default;

$small-font-size: 80% !default;

// Printing
$print-page-size: a3 !default;
$print-body-min-width: map-get($grid-breakpoints, "lg") !default;