.m-radioGroupContainer {
  display: flex;
  flex-direction: column;

  &__options {
    display: flex;
    flex-wrap: wrap;
  }

  label {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
  }
}