.a-breadcrumbs {
    display: flex;
    align-items: center;

    &__item {
        display: flex;
        align-items: center;
        cursor: pointer;
        font-size: 12px;
        line-height: 20px;
        color: $blue-4;

        &.-active {
            color: $primary-color;
        }
    }

    i {
        border-width: 0 1px 1px 0;
        border-color: $gray-10;
        border-style: solid;
        width: 6px;
        height: 6px;
        transform: rotate(-45deg);
        margin: 10px;
    }
}