.m-successBarometer {
    &__block {
        margin-top: 40px;

        h3 {
            text-transform: uppercase;
        }
    }

    &__blockInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;

        @include media-up(l) {
            flex-direction: row;
        }
    }

    &__rate {
        position: relative;

        @include media-up(l) {
            margin-right: 45px;

        }
    }


    &__rateNumText {
        position: absolute;
        bottom: 5px;
        left: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        transform: translateX(-50%);
    }

    &__rateNum {
        font-size: 30px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 30px;
    }

    &__rateText {
        margin-top: 4px;
        color: $gray-6;
        font-size: 10px;
        letter-spacing: 1px;
        line-height: 11px;
        text-align: center;
        text-transform: uppercase;
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 10px;

        @include media-up(m) {
            flex-direction: row;
            align-items: end;
        }

        @include media-up(l) {
            margin-top: 0;
        }

        .m-inputGroup {
            margin-top: 20px;

            @include media-up(m) {
                margin-top: 0;
                margin-right: 30px;
            }
        }

        .m-inputGroup,
        .a-btn {

            margin-top: 20px;

            @include media-up(m) {
                margin-top: 0;
            }
        }

        .a-btn {
            height: 44px;
            margin-top: 25px;

            &.-secondary {
                @include media-up(m) {
                    margin-left: 10px;
                }
            }
        }
    }

    &__values {
        position: relative;
        display: flex;
        width: 100%;
        flex-direction: column;

        @include media-up(xs) {
            flex-direction: row;
        }

        .infoBox {
            display: flex;
            align-items: center;
            background-color: $white;
            border: 1px solid rgba(1,143,208,0.1);
            width: 100%;
            border-radius: 3px;
            padding: 20px 15px;
            box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);


            &:not(:last-of-type) {
                margin-right: 30px;
                margin-bottom: 10px;
            }

            @include media-up(xs) {
                width: 30%;
                
                &:not(:last-of-type) {
                    margin-bottom: 0;
                }
            }

            .info {
                display: flex;
                flex-direction: column;
            }

            .iconContainer {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background-color: rgba($base-color, 0.3);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
            }

            .title {
                font-size: 10px;
                line-height: 11px;
                letter-spacing: 2px;
                color: #383F52;
                opacity: 0.5;
                text-transform: uppercase;
                margin-bottom: 2px;
            }

            .value {
                font-size: 18px;
                line-height: 24px;
                color: #383F52;
            }
        }


    }

    &__edit {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        top: -25px;
        right: 0;
    }
}