.avatar {
  cursor: pointer;
  vertical-align: middle;
  width: 75px;
  height: 75px;
  padding: 5px;
  border-radius: 50%;
}

.avatar-edit {
  position: absolute;
  bottom: 0;
  top: 0;
  width: 15px;
  height: 15px;
  background-color: $gray-400;
}

.input-group {
  display: flex;
  flex-direction: column;
  color: $gray-800;

  input,
  select {
    display: block;
    width: 100%;
    padding: 5px 10px;
    border: 1px solid $gray-300;
    margin: 2px 0;
    border-radius: $border-radius;

    &:focus {
      outline: 0;
      border-color: $color-info;
    }

    &.error {
      border-color: $color-error;
    }
  }

  label,
  span {
    display: block;
  }

  span {
    font-size: 13px;
    color: $color-error;
  }
}

form {
  button[type="submit"] {
    margin-top: 20px;
  }
}