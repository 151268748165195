.a-btnSave {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: $white;
    padding: 8px 13px;
    background-color: $green-1;
    border-radius: 6px;
    width: 90px;
    cursor: pointer;

    &.-fill {
        
        svg {
            fill: $white;
        }
    }

    svg {
        margin-left: 10px;
    }
}