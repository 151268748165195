.p-savedSearch {
    width: 100%;

    &__infoContainer {
        margin-top: 30px;

        @include media-up(l) {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        
    }

    .m-heatmap {
        margin-top: 25px;
    }

    .m-heatmap__resultsDisplaying {
        font-size: 26px;
        line-height: 26px;
    }
}