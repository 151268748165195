.main-content {
  transition: 0.5s;
  margin: 60px;
}

.m-inputGroup,
.m-advancedFilter__stepperBodyGroupCheckboxes,
.m-multiselectGroup,
.m-fileDragAndDrop,
.m-checkboxGroup,
.m-selectGroup,
.m-selectOptgroup,
.m-textareaGroup,
.m-modalLocation__contry,
.m-modalLocation__region {
  margin-top: 20px;
}