$time: 2.5s;
$delay: $time / 14;
$hexa-color: $secondary-color;

.loader-container {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 21;
}

#hexas path {
  fill: $hexa-color;
}

@for $i from 0 through 6  {
  #hexas path:nth-child( #{$i + 1} ){
    animation: hexa $time #{$i * $delay} infinite;
    transform-origin: 50% 50%;
  }
}

@keyframes hexa {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  15%, 50% {
    transform: scale(0.5);
    opacity: 0;
  }
  65% {
    transform: scale(1);
    opacity: 1;
  }
}