.m-avatar {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;

  &__imgInfo {

    display: flex;
    align-items: center;
    justify-content: center;

  }

  // Not using for now
  // &-initials {
  //   font-size: 18px;
  //   line-height: 50px;
  //   font-weight: 600;
  //   color: $white;
  // }

  &__img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
    width: 38px;
    border-radius: 50%;
    user-select: none;
    border: 1px solid $secondary-color;
    background-color: rgba(223, 223, 223, 0.5);

    @include media-up(m) {
      width: 44px;
      height: 44px;
    }

    img {
      width: 38px;
      height: 38px;
      background-color: $color-primary;
      object-fit: cover;
      border-radius: 50%;

      @include media-up(m) {
        width: 44px;
        height: 44px;
      }
    }
  }

  &__icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__info {
    display: flex;
    color: $color-primary;
    flex-direction: column;
    margin: 0 17px 0 8px;
  }

  &__name {
    font-size: 12px;
    line-height: 22px;
    white-space: nowrap;
    font-weight: 600;
    letter-spacing: -0.23px;
    display: none;

    @include media-up(s) {
      display: block;
    }

    @include media-up(m) {
      font-size: 16px;
    }
  }

  &__title {
    font-size: 10px;
    line-height: 12px;
    opacity: 0.5;
    font-weight: 600;

    @include media-up(m) {
      font-size: 14px;
    }
  }

  &__popup {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50px;
    right: -5px;
    width: max-content;
    min-width: 200px;
    transform: translateY(-20px);
    background: $white;
    padding: 4px;
    box-sizing: border-box;
    border: 1px solid rgba($blue-1, .2);
    border-radius: 10px;
    background-color: $white;
    z-index: 10;
    opacity: 0;
    pointer-events: none;
    animation-name: show;
    animation-duration: .3s;
    transition: opacity .3s, transform .3s;

    @include media-up(m) {
      right: -10px;
      top: 55px;
    }

    &.-open {

      transform: translateY(0);
      opacity: 1;
      pointer-events: initial;
    }




    &::before {
      content: '';
      position: absolute;
      top: -5px;
      right: 40px;
      display: block;
      width: 7px;
      height: 7px;
      background-color: $white;
      border: 1px solid rgba($blue-1, .2);
      border-right: 0;
      border-bottom: 0;
      transform: rotate(45deg);
    }

  }


  &__item {
    display: flex;
    align-items: center;
    padding: 10px 8px 0;
    font-weight: 600;
    color: $color-primary;
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;

    svg {
      transition: stroke .3s;
    }

    &:hover {

      svg {
        stroke: $secondary-color;
      }
    }

    &.-upgrade,
    &.-logout {
      width: 100%;
      padding: 8px 15px;
      border-radius: 10px;
    }

    &.-upgrade {
      background-color: $primary-color;
      color: $white;
      font-weight: 600;
      transition: background-color .3s;

      &:hover {
        background-color: $secondary-color;
      }
    }

    &.-logout {
      margin-top: 10px;
      background-color: $gray-10;
      transition: color .3s, background-color .3s;

      &:hover {
        background-color: $secondary-color;
        color: $white;
      }
    }

    svg {
      margin-right: 10px;
    }
  }

  &:hover {
    svg {
      color: $secondary-color;
    }
  }


}