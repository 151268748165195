.m-textareaGroup {
    position: relative;
    width: 100%;

    textarea {
        width: 100%;
        height: 132px;
        padding: 10px 15px;
        border-radius: 6px;
        border: 1px solid $gray-11;
        background-color: $gray-13;
        outline: 0;
        color: $blue-5;
        font-size: 13px;
        transition: border-color .3s, background-color .3s;
        filter: none;
        resize: none;

        &:invalid {
            box-shadow: none;
        }

        &:focus {
            background-color: $white;
            border-color: $secondary-color;

            ~svg {
                stroke: $secondary-color;
            }
        }

        &::placeholder {
            opacity: 1;
            color: $gray-6;
        }
    }

    &.-error {
        textarea {
            border-color: $red;
        }
    }

    &__error {
        position: absolute;
        bottom: -12px;
        left: 0;
        color: $red;
        opacity: 0;
        font-size: 13px;
        transition: opacity .3s;
        letter-spacing: -0.2px;
        line-height: 16px;

        &.-active {
            opacity: 1;
        }
    }
}