.a-btn {
    transition: color .3s, background-color .3s;

    &:active {
        border-style: outset;
    }

    &.-secondary {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 14px;
        background-color: $primary-color;
        color: $white;
        border-radius: 6px;
        padding: 14px 16px;
        display: inline-block;
        cursor: pointer;
        outline: none;
        border: 0;

        &.-grayHover {
            &:hover {
              background-color: $gray-700;
            }
          }
    }

    &.-outlined {
        border-radius: 6px;
        border: 1px solid $secondary-color;
        background: transparent;
        outline: none;
        color: $white;
        padding: 20px 40px;
        line-height: 1;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: -0.2px;
        cursor: pointer;
    }

    &.-primary {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 14px;
        background-color: $secondary-color;
        color: $white;
        border-radius: 6px;
        padding: 14px 18px;
        display: inline-block;
        cursor: pointer;
        outline: none;
        border: 0;

        &.-hamburger {
            display: block;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1px 6px;

            @include media-up(m) {
                display: none;
            }
        }
    }

    &.-tertiary {
        background-color: $white;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 14px;
        color: $gray-14;
        border-radius: 6px;
        padding: 14px 18px;
        display: inline-block;
        cursor: pointer;
        outline: none;
        border: 1px solid $gray-10;
    }

    &.-btnEffect {
        @include media-up(xl) {
            position: relative;
            display: inline-block;
            z-index: 1;

            &::after {
                border-radius: 6px;
                position: absolute;
                content: "";
                width: 0;
                height: 100%;
                top: 0;
                right: 0;
                z-index: -1;
                background: $white;
                transition: all 0.3s ease;
            }

            &:hover {
                color: $secondary-color;

                &::after {
                    left: 0;
                    width: 100%;
                }
            }

        }
    }

    &:not(.-disabled) {
        &:hover {
            background-color: $black;
            color: $white;
        }
    }

    &.-disabled {
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 14px;
        padding: 14px 18px;
        display: inline-block;
        cursor: default;
        outline: none;
        border: 0;
        border-radius: 5px;
        border-radius: 5px;
        background-color: $gray-10;
        transition: opacity, color .3s;

        &:hover {
            opacity: 1;
        }
    }
}