.results-display{
    display: inline-block;
    margin-top: 10px;
    opacity: 0.5;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    span {
        font-weight: 600;
    }
}
