.m-regionsMap {
    width: 100%;

    &.-country {
      position: absolute;
      top: 8%;
      left: -70%;
    }

    &.-region {
      position: absolute;
      top: 10%;
      margin-top: 0;
    }

    &__container {

      &.-country {
        display: none;
        position: relative;
        height: 100%;
        margin-top: 0;

        @include media-up(l) {
          display: block;
        }
      }

      &.-region {
        display: none;
        position: relative;
        height: 100%;
        margin-top: 0;
        overflow: hidden;

        @include media-up(l) {
          display: block;
        }
      }
      
    }
}