.m-welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @include media-up(l) {
    flex-direction: row;
  }

  &__box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 220px;
    padding: 20px;
    border: 1px solid rgba($white, .2);
    border-radius: 10px;
    background-color: rgba($white, .15);
    cursor: pointer;
    color: $white;
    text-align: center;

    @include media-up(l) {
      width: 287px;
    }

    &:not(last-child) {
      margin-bottom: 20px;

      @include media-up(l) {
        margin: 0 30px 0 0;
      }
    }

    svg {
      stroke: #2C508C;
    }

     h5 {
       margin-top: 15px;
       font-weight: 600;
       font-size: 20px;
       line-height: 26px;

       span {
         font-weight: 600;
         font-size: 16px;
         line-height: 19px;
       }
     }

    &:hover {
      background-color: $white;
      color: $gray-900;

      .m-welcome__boxHiddenContent {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &__boxHiddenContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    background-color: $white;
    border-radius: inherit;
    padding: inherit;
    color: $gray-900;

    img {
      height: 80px;
      width: 80px;
      border-radius: 60px;
      background-color: #D8D8D8;
    }

    h5 {
      font-size: 16px;
      letter-spacing: -0.3px;
      line-height: 20px;
      margin-top: 5px;
    }

    span {
      opacity: 0.5;
      font-size: 13px;
      letter-spacing: -0.4px;
      line-height: 15px;
    }

    a {
      display: block;
      font-size: 14px;
      line-height: 16px;
      color: $gray-900;

      &:hover {
        color: $color-info;
      }

      &[href^="tel:"] {
        margin-top: 10px;
      }
    }
  }
}