.m-searchGlobal {
    position: relative;

    &__input {
        height: 35px;
        width: 100%;
        border: 1px solid $gray-10;
        border-radius: 5px;
        background-color: $white;
        padding: 0 5px 0 25px;
        outline: 0;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 20px;
        transition: border-color .3s;

        &:focus {
            border-color: $secondary-color;
        }

        @include media-up(s) {
            padding: 0 10px 0 43px;
        }

        @include media-up(m) {
            padding: 0 43px;

        }

        @include media-up(l) {
            // width: 475px;
            width: 400px;
            height: 44px;
        }

        @include media-up(xxl) {
            width: 555px;
        }
    }

    svg {
        position: absolute;
        left: 5px;
        top: 50%;
        width: 18px;
        height: 18px;
        transform: translateY(-50%);

        @include media-up(s) {
            left: 10px;
            width: initial;
            height: initial;
        }
    }

    &__close {
        position: absolute;
        top: 0;
        right: -25px;
        width: 20px;
        height: 100%;
        cursor: pointer;
        transition: transform .3s;
        transform: rotate(0);

        &:hover {
            transform: rotate(90deg);
        }

        @include media-up(m) {
            top: 0;
            right: 10px;
            width: 24px;
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            width: 100%;
            height: 1.5px;
            background-color: $secondary-color;
        }

        &::before {
            transform: translate(-50%, -50%) rotate(45deg);
        }

        &::after {
            transform: translate(-50%, -50%) rotate(-45deg);

        }
    }

    &.-tableSearch {

        margin-top: 50px;

        @include media-up(l) {
            width: 475px;
            height: 44px;
        }

        @include media-up(xxl) {
            width: 555px;
        }
    }
}