.m-packages {
  display: flex;
  border-radius: 5px;
  background-color: $white;
  justify-content: space-between;
  padding: 20px;
  position: relative;
  overflow: auto;

  @include media-down(m) {
    display: flex;
    width: 100%;
  }


  &__wrapper {
    padding: 50px 0;

    @include media-up(l) {
      max-width: 820px;
      margin: 20px auto 0;
    }
  }

  .m-list.-underline {
    margin-top: 45px;

    li {
      padding: 5px 0;
      min-height: 35px;
      span {
        // display: block;
        line-height: 14px;
        // margin-top: 5px;
      }
    }
  }

  &__desc {
    display: flex;
    flex-direction: column;
    margin-right: 15px;
    min-width: 190px;

    .a-btn.-primary {
      margin-top: auto;
      text-align: center;
      margin-bottom: 10px;
      height: max-content;

      @include media-up(l) {
      height: 44px;
      margin-bottom: 0;
    }
    }

    @include media-down(m) {
      width: 100%;
    }


    @include media-up(l) {
      flex: 1;
    }

    .m-list.-underline {
      margin-top: 10px;

      li {
        font-size: 14px;
        
        &:not(:last-child) {
          border-bottom: 1px solid $gray-4;
        
        }
        
        span {
          display: flex;
          min-height: 35px;
          font-size: 14px;
          align-items: center;
          color: rgba($gray-14, .6);
          padding: 5px 0;

          &:first-of-type {
            padding-top: 17px;
          }
          &:not(:first-of-type) {
            padding-bottom: 0;
          }
        }
      }
    }
  }

  &__pricings {
    height: 215px;

    h4 {
      font-weight: bold;

      &.-professional {
        color: $orange-1;
      }
    }

    &.-duration {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      height: 283px;

       @include media-up(l) {
         margin-top: 0;
       }

       h5 {
         margin-top: auto;
         font-weight: bold;
       }


    }
  }



  &__items {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;

    @include media-down(l) {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
    }
  }



  &__package {
    position: relative;
    padding: 10px;
    padding-top: 17px;
    margin: 0 10px 0 10px;
    border-radius: 5px;
    //background-color: $gray-17;
    border: 1px solid $gray-5;
    color: $gray-16;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    min-width: 230px;
    max-width: 230px;

    &.-selected {
      border-top: 8px solid $orange-1;
        padding-top: 10px;
        button {
          &.-selectPackage {
            background-color: $orange-1;
  
          }
        }
      }

    &--header {
     margin-bottom: 15px;


    }

    &--note {
      height: 80px;
      margin-top: 15px;
      font-size: 14px;
      padding-bottom: 20px;
    }

    &--pricingInfo {
      margin-top: 10px;
      font-size: 14px;
    }

    @include media-up(l) {
      width: 200px;
      margin: -10px 10px -10px 0;
    }
    
    @include media-down(m) {
      width: 100%;

     
    }

    

    h2 {
      font-weight: 600;
      font-size: 28px;
      line-height: 1;

      @include media-down(m) {
        font-size: 22px;
      }

      span {
        opacity: 0.5;
        font-size: 13px;
        letter-spacing: -0.5px;
        line-height: 15px;
        font-weight: 400;
      }
    }

    &:last-of-type {
      margin-right: 0;
    }

    li {
      text-align: center;

      &:not(:last-child) {
        border-bottom: 1px solid rgba($secondary-color, .1);
      }
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 44px;
      border: 0;
      color: $white;
      font-weight: 600;
      font-size: 13px;
      letter-spacing: -0.2px;
      line-height: 16px;
      border-radius: 5px;
      background-color: $red-4;
      cursor: pointer;
      transition: background-color .2s;

      &:hover {
        background-color: rgba($secondary-color, .2);
      }

      &:focus {
        outline: none;
      }


    }

    button {
      &.-free {
        cursor: not-allowed;
        background-color: transparent;
        border: 1px solid $gray-5;
        color: $gray-6;
      }

      &.-upgrade {
        background-color: $orange-1;
        color: $white;
        transition: color .3s, background-color .3s;

        &:hover {
          background-color: $secondary-color;
          color: $white;
        }
  
      }
    }
    &--fullFeeLink {
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      line-height: 1;
      transition: transform .3s ;
      color: $blue-3;
      font-weight: bold;
      text-decoration: underline !important;

      &:hover {
        transform: translateX(5px);
      }
      svg{
        margin-left: 6px;
        margin-top: 3px;
      }
    }

  }

  &__promotion {
    position: absolute;
    top: 0;
    left: 20px;
    transform: translateY(-50%);
    padding: 5px 10px;
    font-size: 10px;
    line-height: 20px;
    border-radius: 5px;
    background-color: $orange-1;
    color: $white;
  }


}
