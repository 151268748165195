//All temporary styling is set because there is no slider

.m-stories {

    //temporary - remove padding bottom when sliders comes
    padding: 80px 0 150px;

    background-image: url(../../images/stories-bkg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: $gray-3;

    &__title {
        letter-spacing: -1px;
        line-height: 52px;
        margin-bottom: 50px;
        text-align: center;
    }

    &__slider {
        //temporary start
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @include media-up(l) {
            flex-wrap: nowrap;
        }

        //temporary end
        &--text {
            background-color: $white;
            padding: 40px 45px 36px 35px;
            position: relative;
            border: 1px solid $gray-10;
            border-radius: 5px;
            box-shadow: 0 20px 50px -30px rgba($black, 0.15);

            &::before,
            &::after {
                top: 100%;
                left: 7%;
                border: solid transparent;
                content: "";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(136, 183, 213, 0);
                border-top-color: $white;
                border-width: 13px;
                margin-left: -13px;
            }

            p {
                color: $gray-8;

                &:nth-of-type(1) {
                    font-size: 20px;
                    font-weight: 600;
                    margin-bottom: 8px;
                }

                &:nth-of-type(2) {
                    margin-bottom: 0;
                    opacity: 0.8;
                }
            }
        }

        &--info {
            display: flex;
            margin-top: 35px;

            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: 15px;
            }

            p {
                &:nth-of-type(1) {
                    font-weight: 600;
                    margin-bottom: 0;
                }

                &:nth-of-type(2) {
                    opacity: 0.5;
                }
            }
        }
    }

    //temporary start
    &__slide {
        width: 100%;
        max-width: 100%;
        margin-top: 40px;

        @include media-up(l) {
            max-width: 606/1240*100%;
            margin-top: 0;
        }
    }

    //temporary end
}