.m-cardsOverview {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // align-items: center;
    margin-top: 30px;

    @include animation-fade-in;

    &__card {
        background-color: $white;
        border: 1px solid $gray-10;
        width: 100%;
        // height: 250px;
        margin-bottom: 30px;
        padding: 24px 0 0;
        border-radius: 5px;
        position: relative;

        @include media-up(m) {
            width: calc(50% - 15px);
        }

        @include media-up(l) {
            padding: 24px 0 29px;
        }

        &.-fullWidth {
            width: 100%;
        }

        &.-vendor {
            display: flex;
            flex-direction: column;
            width: 100%;

            ::-webkit-scrollbar {
                height: 6px;
              }
            
            ::-webkit-scrollbar-track {
                background-color: #ececec;
                border-radius: 4px;
                opacity: 0.5;
            }
        
            ::-webkit-scrollbar-thumb {
                background: $secondary-color;
                border-radius: 4px;
            }
        }

        &.-chart {
            padding: 24px 24px 29px;
        }

        ::-webkit-scrollbar {
            width: 6px;
          }
        
        ::-webkit-scrollbar-track {
            background-color: #ececec;
            border-radius: 4px;
            opacity: 0.5;
        }
    
        ::-webkit-scrollbar-thumb {
            background: $secondary-color;
            border-radius: 4px;
        }

        .m-upgradeToPremium {
            padding: 15px 20px;
            font-size: 12px;
        }
    }

    .m-savedSearchInfo {
        width: 100%;
        height: auto;
        min-height: 300px;
        margin-bottom: 30px;
        border: 1px solid #d1d1d9;

        @include media-up(m) {
            width: calc(50% - 15px);
        }
    }

    &__title {
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        letter-spacing: -0.2px;
        margin-bottom: 15px;
        padding: 0 29px;
        color: $blue-4;
        text-align: center;

        @include media-up(l) {
            text-align: initial;
        }

        &.-tab {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .tabItem {
                color: #b9bec8;
                margin-right: 30px;
                cursor: pointer;
                transition: color .3s;
                white-space: nowrap;

                &.-active {
                    color: $black;
                }
            }
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        font-size: 16px;
        line-height: 22px;
        display: flex;
        margin-top: 10px;
        padding: 0 15px 10px;
        border-bottom: 1px solid $gray-10;
        // &:not(:last-child) {

        //     border-bottom: 1px solid $gray-10;
        // }

        @include media-up(l) {
            flex-direction: row;
            align-items: initial;
            text-align: initial;
            padding: 0 29px;
            padding-bottom: 0;
            border-bottom: 0;
            // &:not(:last-child) {

            //     border-bottom: 0;
            // }

        }
    }

    &__infoTitle {
        // width: 173px;
        width: max-content;
        color: rgba($blue-4, 0.7);
        white-space: nowrap;
    }

    &__infoDescription {
        font-size: 12px;
        line-height: 16px;
        color: #001234;
        opacity: 0.5;
        margin-right: 20px;
    }

    &__infoValue {
        width: 60%;
        color: $blue-4;
        margin-top: 10px;

        &.-hidden {
            filter: blur(3px);
        }

        @include media-up(l) {
            margin-top: 0;
        }
    }

    &__infoImage {
        height: 20px;
        width: 25px;
    }

    &__container {
        display: flex;
        flex-flow: column;

        &.-hidden {
            filter: blur(3px);
        }
        
        // @include media-up(l) {
        //     flex-flow: row;
        // }

        &.-team {
            height: 300px;
            min-height: 80%;
            overflow-y: auto;
            flex-direction: column;

            @include media-up(m) {
                flex-direction: row;
                flex-wrap: wrap;
            }
        }

        &.-vendor {
            margin: 0 15px;
            padding: 15px 0;
            overflow-x: auto;
            height: 100%;
            justify-content: center;

            table, tr {
                background-color: $white;
                font-size: 16px;
            }

            th {
                border-bottom: 1px solid rgba(0, 18, 52, 0.2);
                cursor: default;
            }

            td {
                padding: 15px 10px;
                border-bottom: 1px solid rgba(0, 18, 52, 0.2);
            }

            .title {
                text-align: left;
                color: rgba(0, 18, 52, 0.7);
                font-size: 16px;
                line-height: 22px;
                white-space: nowrap;
                position: sticky;
                left: -1px;
                background-color: $white;
                width: 100px;
            }

            .logo {
                img {
                    width: 100px;
                    // padding: 10px;
                }
            }

            .more {
                background-color: rgba(223, 223, 223, 0.5);
                padding: 5px 10px;
                border-radius: 5px;
                font-size: 14px;
                cursor: pointer;
            }

            .m-modal {
                padding: 40vh 0;
            }

            .m-modal__body {
                padding: 20px;
            }

            .vendorOtherDetails {
                img {
                    width: 100px;
                }

                .details {
                    margin-top: 30px;
                }
            }
        }
    }

    &__teamMember {
        display: flex;
        flex-direction: row;
        padding: 15px 30px;
        width: 100%;

        @include media-up(m) {
            width: 45%;
        }

        &.-noInfo {
            opacity: 0.7;
        }

        .image {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(223, 223, 223, 0.5);
            width: 70px;
            height: 70px;
            min-width: 70px;
            min-height: 70px;
            border-radius: 10px;
            margin-right: 15px;
        }

        .info {
            display: flex;
            flex-direction: column;
            font-size: 16px;
            line-height: 24px;

            .position {
                font-size: 14px;
                line-height: 24px;
                opacity: 0.5;
                margin-bottom: 6px;
                margin-top: 5px;
            }
        }
    }

    .m-savedSearchInfo {
        position: relative;

        .m-savedSearchInfo__title {
            font-size: 20px;
            line-height: 30px;
            font-weight: 600;
            letter-spacing: -0.2px;
            margin-bottom: 15px;
            color: $blue-4;
        }
    
        &.full-width {
            width: 100%;
            // height: auto;
            margin-bottom: 30px;
            border: 1px solid #d1d1d9;

            // @include media-up(m) {
            //     width: calc(50% - 15px);
            // }
        }

        .headquartersHidden {
            filter: blur(3px);
            width: 100%;
            height: 100%;
        }

        .m-upgradeToPremium {
            padding: 15px 20px;
            font-size: 12px;
        }
    }

    &__pinInfo {
        .m-map__address {
            font-size: 14px;
        }

        .companies {
            padding: 10px;
            display: flex;
            flex-direction: column;

            span {
                cursor: pointer;
                font-size: 13px;
                font-weight: 600;
                margin-top: 10px;
                color: rgba($secondary-color, 0.7);
                transition: color 0.2s;

                &:hover {
                    color: $primary-color;
                }
            }
        }
    }

    .gm-ui-hover-effect { 
        &:focus {
            outline: none;
        }
    }
}