.p-welcome {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 30px 20px 85px;
  background-image: linear-gradient(90deg, rgba($secondary-color, .85), rgba($secondary-color, .85)),
  url(../../images/login-hero.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &__text {
    color: $white;
    margin-top: 30px;
    text-align: center;

    h2 {
      margin-top: 30px;
      font-weight: 600;
    }

    p {
      margin-top: 5px;
    }

    h4 {
      margin-top: 40px;
    }
  }

  .m-welcome {
    margin-top: 30px;
  }
}