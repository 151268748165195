.m-imprint {
    padding: 50px 100px 150px;

    img {
        display: block;
        margin: auto;
        max-width: 160px;

        @include media-up(l) {
            max-width: 250px;
        }
    }

    &__parts {
        text-align: center;
        width: 100%;
        margin-bottom: 20px;

        &:last-of-type {
            margin-bottom: 0;
        }
        span {
            font-size: 18px;
            font-weight: bold;
        }

    }
}