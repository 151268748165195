.a-atom {
    &.-yellowText {
        font-size: 12px;
        letter-spacing: 4px;
        line-height: 26px;
        color: $orange-1;
        text-transform: uppercase;
        position: relative;
        z-index: 1;
    }

    &.-blueLine {
        position: relative;
        display: inline-block;

        &::before {
            content: "";
            position: absolute;
            width: 60px;
            height: 2px;
            background-color: $blue-3;

            left: 50%;
            transform: translate(-50%);

            @include media-up(xl) {
                left: unset;
                transform: unset;
            }
        }
    }

    &.-arrowRight {
        position: relative;
        margin-right: 17px;

        &::before {
            content: "";
            position: absolute;
            border: solid $white;
            border-width: 0 1px 1px 0;
            display: inline-block;
            padding: 2px;
            opacity: 0.5;
            transform: rotate(-45deg);
            top: 7px;
        }
    }

    &.-linkEffect {
        @include media-up(xl) {
            position: relative;
            display: inline-block;

            &:before {
                content: "";
                position: absolute;
                bottom: -5px;
                left: 0;
                right: 50%;
                height: 2px;
                background-color: $orange-1;
                transform: scaleX(0);
                transform-origin: bottom left;

                transition: transform 0.3s;
            }

            &:after {
                content: "";
                position: absolute;
                bottom: -5px;
                right: 0;
                left: 50%;
                height: 2px;
                background-color: $orange-1;
                transform: scaleX(0);
                transform-origin: bottom right;

                transition: transform 0.3s;
            }

            &:hover {
                &:before {
                    transform: scaleX(1);
                }

                &:after {
                    transform: scaleX(1);
                }
            }
        }
    }

    &.-arrowDown {
        height: 0px;
        width: 0px;
        border: none;
        border-top: 4px solid $secondary-color;
        border-left: 4px solid rgba(0, 0, 0, 0);
        border-right: 4px solid rgba(0, 0, 0, 0);
        display: inline-block;
        margin: 0 0 2px 2px;
    }
}
