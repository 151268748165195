.m-slider {
    margin: 50px 0;
    width: 100%;
    padding: 25px;
    overflow: hidden;

    @include media-up(s) {
        margin: 50px;
    }

    .slider-handle {
        display: flex;
        justify-content: center;
    }

    .slider-handle-value {
        position: absolute;
        top: calc(-100% - 14px);
        left: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateX(-50%);
        margin-top: -32px;
        white-space: nowrap;
        font-size: 14px;
        padding: 9px 14px 8px;
        border-radius: 10px;
        background-color: $secondary-color;
        color: $white;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 20px;
    }
}

//OVERRIDING THE DEFAULT STYLING OF RC SLIDER
.rc-slider {
    .rc-slider-rail {
        background-color: rgba($secondary-color, .2);
    }

    .rc-slider-track {
        background-color: $secondary-color;
        transition: width .3s;
    }

    .rc-slider-handle {
        height: 12px;
        width: 12px;
        border: 2px solid $secondary-color;
        border-radius: 6px;
        background-color: $white;
        margin-top: -4px;

        &:focus {
            border-color: $secondary-color;
        }
    }

    .rc-slider-mark-text {
        color: rgba($blue-4, .5);
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 19px;

        &-active {
            color: $blue-4;
        }
    }

    .rc-slider-dot {
        width: 16px;
        height: 16px;
        bottom: -6px;
        margin-left: -8px;
    }

    .rc-slider-dot-active, .rc-slider-handle-dragging {
        border-color: $secondary-color;
    }

    .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
        border-color: $secondary-color;
        box-shadow: 0 0 0 5px $secondary-color;
    }

    .rc-slider-handle:hover {
        border-color: $secondary-color;
    }
}