.m-cardsNews {
  @include animation-fade-in;
  margin-top: 20px;

  &.-blured {
    filter: blur(3px);
    pointer-events: none;
  }

  .itemContainer {
    margin-bottom: 20px;
  }

  &.-list {
    display: flex;
    flex-direction: column;
  }

  &.-small {
    .m-cardsNews__imgContainer {
      height: 100px;
    }

    .m-cardsNews__card {
      height: 100%;
      justify-content: space-between;
    }

    .m-cardsNews__imageContainer {
      width: 100%;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__report {
    display: flex;
  }

  &__reportDate {
    position: relative;
    margin-right: 15px;

    @include media-up(m) {
      margin-right: 30px;
    }

    .date {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 76px;
      height: 76px;
      border: 1px solid rgba($secondary-color, 0.3);
      border-radius: 5px;
      background-color: #FFFFFF;
      padding: 10px;

      &::after {
        content: '';
        position: absolute;
        top: 76px;
        width: 1px;
        height: calc(100% - 76px);
        border: 1px dashed $secondary-color;
      }
    }

    .month {
      opacity: 0.5;
      color: #383F52;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: -0.2px;
      line-height: 16px;
      margin-bottom: 2px;
    }

    .day {
      color: $secondary-color;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: -1px;
      line-height: 24px;
      text-align: center
    }
    
  }

  &__card {
    padding: 10px;
    background-color: $white;
    border: 1px solid $gray-10;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    height: 100%;
    justify-content: space-between;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);

    &.-big {
      flex-direction: row;
      margin-bottom: 20px;
      align-items: center;
    }
  }

  &__imageContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    &.-big {
      display: flex;
      flex-direction: column;
      margin: 0 15px;
      
      @include media-up(m) {
        margin: 0 30px;
      }
    }
  }

  &__img {
    width: 100%;
    // height: 200px;
    object-fit: cover;
    // border-radius: 5px;

    &.-big {
      width: 278px;
      min-width: 278px;
      height: 180px;
    }
  }

  &__imgContainer {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;

    &.-big {
      width: 278px;
      min-width: 278px;
      height: 180px;
    }
  }

  &__imgContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(223, 223, 223, 0.5)
  }

  &__header {
    display: flex;
    flex-direction: column;
    margin: 20px 0;

    @include media-up(l) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__date {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: $secondary-color;
    opacity: 0.5;
    margin-top: 15px;
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    letter-spacing: -0.5px;
    color: $secondary-color;
    margin-top: 5px;
    width: 100%;
  }

  &__description {
    color: #383F52;
    font-size: 14px;
    letter-spacing: -0.2px;
    line-height: 26px;
    margin-top: 10px;

    @include media-up(s) {
      font-size: 16px;
    }
  }

  &__source {
    display: flex;
    margin-top: 15px;
    border: 1px solid rgba($secondary-color, .2);
    background-color: rgba($secondary-color, .05);
    border-radius: 5px;
    padding: 10px;


    img, .image {
        min-width: 34px;
        min-height: 34px;
        width: 34px;
        height: 34px;
        border-radius: 3px;
        margin-right: 10px;
    }

    .image {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(223, 223, 223, 0.5);
    }

    // img {
    //   width: 36px;
    //   height: 36px;
    //   border-radius: 50%;
    //   margin-right: 10px;
    //   background-color: $blue-4;
    // }

    p {
      font-size: 14px;
      line-height: 19px;
      letter-spacing: -0.2px;
      color: $color-primary;
      display: flex;
      flex-direction: column;

    }
  }

  &__sourceName {
    color: $secondary-color;
    cursor: pointer;
  }

  &__readMore {
    position: relative;
    display: inline-block;
    margin-top: 10px;
    font-weight: 600;
    padding-right: 20px;
    font-size: 14px;
    color: $primary-color;
    cursor: pointer;
    transition: transform .3s;
    width: 120px;

    &.-auto {
      white-space: nowrap;

      &::before,
      &::after {
        right: -20px;
      }
    }

    &:hover {
      transform: translateX(10px);

      &::after {
        opacity: 1;
      }
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
    }

    &::before {
      width: 10px;
      height: 1px;
      background-color: $primary-color;
    }

    &::after {
      width: 6px;
      height: 6px;
      border: 1px solid $primary-color;
      transform: translateY(-2.5px) rotate(45deg);
      border-left: 0;
      border-bottom: 0;
      opacity: 0;
      transition: opacity .3s;
    }
  }

  &__filtersContainer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  &__filterDate {
    margin-right: 30px;
  }

  &__inputContainer {
    position: relative;

    button {
      position: absolute;
      right:0;
      top:50%;
      transform: translateY(-50%) rotate(45deg);
      display: block;
      width: 16px;
      height: 16px;
      padding: 0;
      background-color: $primary-color;
      border-radius: 50%;
      border: none;
      outline: none;
      cursor: pointer;
      

      &::after,&::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        background-color: $white;
      }
      &::after {
        width: 9px;
        height: 1px;
      }
      &::before {
        width: 1px;
        height: 9px;
      }
    }
   }

  &__filterDate, &__filterSort {
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: #0D0915;
    font-size: 13px;
    letter-spacing: -0.2px;
    line-height: 16px;

    .m-inputGroup, .m-selectGroup {
      margin-top: 0;
      margin-left: 10px;
    }
  }

  &__reportItems {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .m-cardsNews__cardContainer {
      margin-bottom: 20px;
    }

    .m-cardsNews__card {
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 15px;

      &:hover {
        box-shadow: 0 7px 14px rgba($black, 0.1), 0 10px 10px rgba($black, 0.1);
      }

      .m-cardsNews__text {
        margin: 0;
      }
    }

    &.-dealTrackerCards {

      .m-cardsNews__cardContainer {
        width: 200px;
        margin: 0 10px 20px;
      }

      .m-cardsNews__logoContainer {
        width: 100%;
        cursor: pointer;
      }

      .m-cardsNews__imgContainer {
        height: 65px;
        background-color: rgba(223, 223, 223, 0.4);
        flex-direction: column;
      }

      .m-cardsNews__imgTitle {
        font-size: 13px;
      }

      .m-cardsNews__imageContainer {
        height: 65px;

        img {
          width: 85%;
        }
      }

      .m-cardsNews__card {
        align-items: center;
        position: relative;
        padding: 10px 20px;
        color: $gray-9;

        &:hover {
          box-shadow: 0 7px 14px rgba($black, 0.1), 0 10px 10px rgba($black, 0.1);
        }
      }

      .m-cardsNews__flag {
        position: absolute;

        &.-target {
          top: 0;
          right: 0;
        }

        &.-acquire {
          bottom: 0;
          left: 0;
        }
      }

      .m-cardsNews__date {
        position: absolute;
        top: 5px;
        left: 10px;
        margin-top: 0;
      }

      .m-cardsNews__companiesContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 25px 0px;
        position: relative;
      }

      .m-cardsNews__acquired {
        padding: 5px 0;
        z-index: 15;
      }

      .image {
        display: flex;
      }
    }

    .m-cardsNews__text {
      width: 100%;
    }
  }

  &__scroll {
    position: relative;
    width: 100%;
    height: 100px;
  }

  &__dealTrackerItems {
      display: flex;
  }

  &__flag {
    width: 20px;
  }
}

//TODO Refactor
.single-news {
  .m-cardsNews__title {
    margin-bottom: 20px;
  }

  .m-cardsNews__img {
    height: 300px;
  }

  .m-cardsNews__text {
    font-size: 16px;
    margin-top: 20px;
    color: $color-primary;
  }

  .m-cardsNews__text.--small {
    font-size: 14px;
    opacity: 0.75;
    margin-bottom: 20px;
  }

  
}