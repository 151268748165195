.m-faqModal {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100vh;
  top: -40px;
  left: 0;
  display: none;
  justify-content: center;
  align-items: center;
  padding: 20px;
  overflow-y: auto;
  animation: fadeIn .3s;

  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }

  @include media-up(s) {
    top: 0;
  }

  &.-show {
    display: flex;
  }

  &__content {
    position: relative;
    // width: 100%;
    border-radius: 10px;
    background-color: $white;
    z-index: 25;
    animation: zoomIn .3s;

    @keyframes zoomIn {
      from {transform: scale(0.8);}
      to {transform: scale(1);}
    }


    @include media-down(l) {
      top: 330px;
      width: 100vw;
    }

    @include media-down(m) {
      top: 350px;
    }
    
    @include media-down(xs) {
      top: 450px;
    }


  

    @include media-up(l) {
      display: flex;
      // width: 1170px;
      // height: 670px;
    }
  }

  &__video,
  &__questions {
    padding:  30px;

    @include media-up(m) {
      padding: 25px 30px;
    }
  }

  &__video {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    // @include media-up(m) {
    //   flex-direction: initial;
    //   justify-content: flex-end;
    // }

    @include media-up(l) {
      width: 450px;
    }

    // @include media-up(xl) {
    //   width: 730px;
    // }

    h3 {
      width: 100%;
    }

    iframe {
      margin-top: 40px;
      border: 0;
      border-radius: 10px;
      height: 170px;
      width: 100%;

      @include media-up(l) {
        height: 370px;
      }
    }
    

    &--image {
      position: relative;
      margin: 15px auto 0;
      max-width: 85%;
    }

    &--overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      transition: background-color .3s;

      &::before, &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);

      }

      &::before {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: $primary-color;
      }

      &::after {
        width: 0; 
        height: 0; 
        border-top:9px solid transparent;
        border-bottom:9px solid transparent;
        border-left:14px solid $white;
        transition: transform .3s;
      }

      &:hover {
        background-color: rgba($black, .4);

        &::after {
          transform: translate(-50%,-50%) scale(1.15);
        }
      }
    }

    img {
      display: block;
      object-fit: contain;
    }

    &--quotation {
      // margin-top: 40px;
      padding: 10px;
      font-size: 16px;
      font-style: italic;
      line-height: 27px;

      @include media-up(m) {
        // width: 50%;
        padding: 20px;
        font-size: 18px;
      }
      @include media-up(l) {
        padding: 22px 25px 10px 10px;
        font-size: 14px;
        line-height: 25px;

      }
       @include media-up(xl) {
        font-size: 17px;
        line-height: 27px;
      }

      span {
        margin-right: 5px;
        font-size: 32px;
        // font-style: normal;
        vertical-align: bottom;

        @include media-up(xl) {
          font-size: 36px;
          margin-right: 10px;
        }
      }
    }

    &--signature {
      text-align: center;
      font-size: 14px;
      margin: 10px 0 30px;

      @include media-up(xl) {
        margin: 10px 50px 15px;
      }
    }
  }

  &__questions {
    background-color: #F5F5FA;
    overflow-y: auto;
    border-radius: 0 20px 20px 0;
    @include scrollbar;

     @include media-down(xs) {
       border-radius: 0 0 20px 20px;
     }
    h3 {
      margin: 10px 25px 20px 0;
      border-bottom: 1px solid $black;
    }

    p {
      font-size: 18px;
      font-weight: 600;
    }
    ul {
      // width: 80%;
      margin-top: 20px;
      padding: 0;
      color: $gray-900;
      font-size: 16px;
      letter-spacing: -0.2px;
      line-height: 20px;

      @include media-up(m) {
        width: 80%;
      }

      @include media-up(l) {
        width: 400px;
      }

      @include media-up(xl) {
        width: 375px;
      }

      li {
        font-size: 18px;
        line-height: 26px;

        span {
          font-weight: bold;
        }
        &:not(:first-child) {
          margin-top: 15px;
        }

        &::before {
          content: '';
          display: inline-block;
          margin-right: 10px;
          transform: rotate(45deg);
          height: 12px;
          width: 6px;
          border-bottom: 1.5px solid $secondary-color;
          border-right: 1.5px solid $secondary-color;
        }
      }
    }
  }

  &__button {
    position: fixed;
    right: 0;
    top: 5%;
    margin-top: -26px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    min-width: 120px;
    border-radius: 15px 0 0 15px;
    background-color: $red-4;
    color: $white;
    font-size: 18px;
    line-height: 24px;
    border: none;
    // border: 2px solid $red-4;
    transition: background-color .3s, color .3s;
    cursor: pointer;
  
     @include media-up(l) {
          top: 50%;
          min-width: 150px;
          font-size: 26px;
          height: 70px;
          padding: 30px 15px;
    }

    @include media-up(l) {
      transform: translateY(-50%);
    }

    svg {
      margin-right: 10px;
    }

    &:hover {
      background-color: $white;
      color:#c81e1e;
      // border-color:$white;

      svg {
        fill: $red-4;
      }
    }

    &:focus {
      outline: 0;
    }
  }

  &__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba($black, .5);
  }
}