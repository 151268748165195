.m-modalLocation {
    position: relative;
    height: 100%;

    @include media-up(l) {
        display: flex;
    }

    &__country {
        display: flex;
        align-items: center;

        .m-selectGroup {
            margin-top: 0;
        }

        .removeIcon {
            margin-left: 20px;
            cursor: pointer;
        }
    }

    &__countryPicker {
        position: relative;
        flex: 1;
        overflow: visible;
        padding-top: 20px;

        @include media-up(l) {
            overflow: hidden;
        }


        &.-separator {
            &::after {
                display: none;
                content: '';
                width: 1px;
                background-color: #d1d1d9;
                position: absolute;
                right: 0;
                top: -20px;
                bottom: 0;

                @include media-up(l) {
                    display: block;
                }
            }
        }

        &.-paddingLeft {
            padding-left: 0;

            @include media-up(l) {
                padding-left: 15px;
            }
        }
    }

    &__separator {
        width: 1px;
        height: 105%;
        background-color: #d1d1d9;
        position: absolute;
        top: -20px;
        left: 370px;
        display: none;

        @include media-up(l) {
            display: none;
        }
    }

    .m-selectGroup {
        margin-top: 0;
        width: 300px;
    }

    .locationRadius {
        position: absolute;
        bottom: 195px;
        left: 40px;
        width: 200px;
        font-weight: 600;

        label {
            display: block;
            padding: 5px;
            background-color: #ea4335;
            color: $white;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
        }

        input {
            border-radius: 0;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
            border-top: 0px;
            border-color: #888e92;
            width: 200px;
        }

        input[type=number]::-webkit-inner-spin-button, 
        input[type=number]::-webkit-outer-spin-button {  
            opacity: 1;
        }
    }
}