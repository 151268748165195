.m-noAuth {
    position: relative;
    min-height: 100vh;
    
    &__logoForm {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 30px 20px 150px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &.-welcome {
            width: 100%;
            background-color: $gray-12;

            .a-logo {
                margin-left: 20px;

                @include media-up(m) {
                    margin-left: 30px;
                }
            }
        }
    }

    &__activateUser {
        a {
            text-decoration: underline;
        }
    }

    &__confirm {
        margin-top: 20px;
        padding-right: 20px;
        font-size: 14px;
    }

    &__topText {

        &.-center {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        img {
            margin-bottom: 10px;
        }

        h1 {
            text-align: center;
            color: $white;
            font-weight: 600;
        }

        h3 {
            max-width: 55%;
            margin-top: 10px;
            text-align: center;
            font-weight: 600;
            color: $white;

            @include media-down(m) {
                max-width: 90%;
            } 
        }

        h4 {
            margin-bottom: 10px;
            font-size: 30px;
            text-align: center;
            color: $white;

            @include media-up(xs) {
                width: 80%;
            }
             @include media-up(s) {
                width: 70%;
            }
             @include media-up(m) {
                width: 65%;
            }
             @include media-up(l) {
                width: 60%;
            }
        }

        h2 {
            max-width: 100%;
            text-align: center;
            font-weight: 600;
            color: $white;
            font-size: 28px;

            @include media-up(xs) {
                max-width: 85%;
            }
            @include media-up(s) {
                max-width: 70%;
            }
            @include media-up(m) {
                max-width: 50%;
            }
            @include media-up(l) {
                max-width: 40%;
            }
            @include media-up(xl){
                max-width: 480px;
                font-size: 36px;
            }
        }

        p {
            margin-top: 20px;
            text-align: center;
            font-size: 15px;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 25px;
        }
    }

    &__actions {
        width: 100%;
        max-width: 400px;
        margin: 10px auto;

        &.-wide {
            max-width: 820px;

            .a-btn {
                display: block;
                max-width: 395px;
                margin-right: auto;
                margin-left: auto;
            }
        }
    }

    &__footer {
        background-color: #282828;
        margin-bottom: 0;
        position: fixed;
        bottom:0;
        left:0;
        width:100%;
        padding: 20px 0;
        color: $white;
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: auto;
        
        @include media-down(m) {
            padding:5px 0;
        }

        .footerContent {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin:0 auto;
            width:auto;

            @include media-down(l) {
                width: 100% !important;
                align-items: center;
                flex-direction: row;
                justify-content: center;
                margin: 2px 0;
            }

            &__date {

                @include media-down(m) {
                    font-size: 14px;
                }
                @include media-up(l) {
                    order: -1; 
                    margin-right: 15px;
                }

                a {
                    color: $white;
                }
            }
            &__links {
                text-align: center;
                @include media-down(l){
                    width: 100%;
                };
                @include media-down(m){
                    flex-direction: column;
                };
            }

            span {
                margin: 0 10px;
                text-align: left;

                @include media-down(m) {
                    // width: 100%;
                    padding-left:10px;
                    margin:0 3px;
                    // display:block;

                }
                @include media-up(xs) {
                    margin: 0 15px;
                }
              
                a {
                    color: $white;

                    @include media-down(m) {
                        font-size: 12px;
                    }
                }
            }
        } 
        .footerContent__flags{
            margin-left: 0;
            width:150px;
            flex-direction: row;
            @include media-down(xs) {
                padding-left:10px;
                width: auto;
            }
            @include media-down(l) {
                text-align: center;
            }
            &-img {
                width: 25px;
                opacity: 0.5;
                border-radius: 3px;
                cursor: pointer;
                @include media-down(xs) {
                    flex-direction: row;
                    width:30px;
                    height: 18px;
                }
                &.-active {
                    opacity:1;
                }
               
            }
            span {
                margin: 0 10px;
                display: inline-block;
            }
        }     
    }    

    &__form {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        //margin: 85px auto 0;

        form {
            margin-top: 6px;
        }
    }

    &__eyeDiv {
        position: absolute;
        width: 44px;
        height: 44px;
        bottom: 0;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
            height: 24px;
            background-color: $gray-11;
        }

        svg {
            width: 20px;
            height: 20px;
            left: 13px;
        }
    }

    &__span {
        &.-haveAccount {
            max-width: 60%;
            margin-top: 10px;
            color: rgba($gray-900, .8);
            font-size: 18px;
            line-height: 26px;
        }

        &.-or {
            position: relative;
            display: block;
            margin-top: 28px;
            font-size: 13px;
            letter-spacing: 0;
            text-align: center;

            &::after,
            &::before {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                height: 1px;
                width: 174/395*100%;
                background-color: $gray-10;
            }

            &::before {
                left: 0;
            }

            &::after {
                right: 0;
            }
        }

        &.-registerLogin {
            display: block;
            text-align: center;
            margin-top: 18px;
            font-size: 13px;
            font-weight: 600;
            letter-spacing: 0;
            padding-bottom: 14px;

            a {
                position: relative;
                font-size: 13px;
                letter-spacing: 0;
                font-weight: 600;
                color: $white;

                &:hover {
                    &::after {
                        width: 100%;
                    }
                }

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: 0px;
                    width: 0;
                    height: 1px;
                    background-color: $blue-5;
                    transition: width .3s;
                }
            }
        }

        &.-blueLink {
            font-size: 12px;
            color: $white;
    

            a {
                color: $white;
                letter-spacing: 0;
                line-height: 18px;

                &::after {
                    bottom: 0;
                    background-color: $secondary-color;
                }
            }
        }

        &.-blue {
            font-size: 12px;
            letter-spacing: -0.2px;
            line-height: 12px;
            color: $white;
        }
    }

    &__rememberForgot {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 20px;
        font-size: 14px;

        a {
            color: $white;
        }
    }

    .a-btn {
        height: 50px;
        width: 100%;
        font-size: 13px;
        margin-top: 25px;
        transition: background-color .3s, color .3s;

        &:not(.-disabled) {
            &:hover {
                background-color: $red-4;
                color: $white;
            }
        }

        &::after {
            display: none;
        }

        &.-checkMail {
            cursor: pointer;
            display: block;
            margin: 20px auto 0;
            height: 50px;
            width: 290px;
            border-radius: 25px;
            background-color: rgba($secondary-color, .7);
            font-weight: 600;
            font-size: 13px;
            letter-spacing: 0;
            color: $white;
            border: 0;
        }
    }

    &__linkedin {
        display: flex;
        align-items: center;
        height: 44px;
        width: 100%;
        border-radius: 6px;
        margin: 17px auto 0;
        background-color: #0374b3;
        color: $white;
        transition: background-color .3s;

        &:hover {
            background-color: $blue-4;
        }

        span {
            position: relative;
            width: 48px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 30px;

            &::after {
                content: '';
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 24px;
                background-color: $white;

            }
        }

        img {
            width: 20px;
            height: 20px;
            filter: brightness(0) invert(1);
        }
    }

    a {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: -0.2px;
        line-height: 19px;

    }

    &__registerAs {
        margin-top: 20px;

        label {
            position: relative;
            top: 0;
            left: 0;
        }
    }

    &__invEnt {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 14px;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: 16px;


        span {
            padding-left: 32px;
        }
    }

    &__checkList {
        margin: 20px 0 0;
        padding: 0;

        li {
            position: relative;
            padding-left: 23px;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: -0.2px;
            line-height: 18px;
            color: #1b3f6b;
            transition: background-color .3s;

            &:not(:first-child) {
                margin-top: 5px;
            }

            &.-active {
                &::before {
                    background-color: $green-1;

                }
            }

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 14px;
                width: 14px;
                border-radius: 50%;
                background-image: url(../../images/checkWhite.svg);
                background-position: center;
                background-repeat: no-repeat;
                background-color: rgba($green-1, .2)
            }

        }
    }

    &__welcome {
        margin: 0 auto;
        width: 100%;
        max-width: max-content;
        padding: 80px 20px;

        @include media-up(m) {

            padding: 133px 30px 137px;
        }

        @include media-up(xxl) {

            margin-left: 70/886*100%;
        }

    }

    &__hello {
        h2 {
            font-size: 50px;
            font-weight: 400;
            letter-spacing: -2.5px;
            line-height: 58px;

            span {
                font-size: 24px;
            }
        }

        p {
            font-size: 16px;
            color: $blue-4;
            letter-spacing: 0.1px;
            line-height: 19px;
        }
    }

    &__lookingFor {
        margin-top: 45px;

        h3 {
            color: $blue-4;
            font-weight: 600;
            font-size: 24px;
            letter-spacing: -1.2px;
            line-height: 30px;
        }
    }

    &__options {
        display: flex;
        flex-direction: column;
        margin-top: 30px;

        @include media-up(m) {
            margin-top: 18px;
            flex-direction: row;
            justify-content: space-between;

        }
    }

    &__option {
        display: flex;
        align-items: center;
        height: 101px;
        width: 100%;
        max-width: 288px;
        border: 1px solid $gray-10;
        border-radius: 10px;
        background-color: $white;
        transition: transform .3s;
        margin-bottom: 30px;

        &:first-child {
            margin-right: 30px;
        }

        @include media-up(m) {
            width: 288px;
        }
    }

    &__optionImgDiv {
        height: 70px;
        width: 70px;
        margin: 0 15px
    }

    &__optionInfo {
        display: flex;
        flex-direction: column;
    }

    &__optionName {
        color: $blue-4;
        font-size: 16px;
        letter-spacing: -0.2px;
        line-height: 19px;
    }

    &__optionShortInfo {
        margin-top: 2px;
        color: rgba($blue-4, .5);
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
    }

    &__person {
        border-top: 1px solid $gray-10;
        background-color: $white;
        padding: 16px 30px 15px;


        @include media-up(xxl) {
            padding-left: 100/946*100%;
        }
    }

    &__personYour {
        font-weight: 600;
        font-size: 14px;
        letter-spacing: -0.2px;
        line-height: 17px;
    }

    &__personDiv {
        display: flex;
        margin-top: 15px;
        flex-direction: column;

        @include media-up(s) {
            flex-direction: row;
        }
    }

    &__personImg {
        height: 80px;
        width: 80px;
        min-width: 40px;
        margin: 5px 0 15px;

        @include media-up(s) {
            margin: 0 15px 0 0;
        }

        img {
            border-radius: 60px;

        }
    }

    &__personInfo {
        display: flex;
        flex-direction: column;
    }

    &__personName {
        font-weight: 600;
        font-size: 18px;
        letter-spacing: -0.5px;
        line-height: 22px;
    }

    &__personPosition {
        margin-top: 5px;
        color: rgba($blue-4, 0.5);
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
    }

    &__personPhoneEmail {
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
    }

    &__personPhoneEmailSpan {
        display: flex;
        align-items: center;
        color: rgba($blue-2, .3);
        font-size: 13px;
        letter-spacing: 2px;
        line-height: 15px;
        margin-bottom: 10px;
        text-transform: uppercase;

        &:not(:last-child) {
            margin-right: 30px;
        }

        span {
            margin-left: 10px;
            letter-spacing: -0.2px;
            color: $blue-4;
            text-transform: initial;
        }
    }

    &__hero {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -5;
        background-image: url(../../images/login-hero.jpg);
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        &--overlay {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba($white, .2);
        }
    }

    &__accept {
        display: flex;
        align-items: center;
        font-size: 13px;
        margin-top: 20px;

        &:last-of-type{
            margin-top: 10px;
        }

        .m-checkboxGroup {
            margin: 0;
        }

        a {
            color: $gray-14;
            text-decoration: underline;
        }
    }
}