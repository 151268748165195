.m-radioGroup {
  display: flex;



  input {
    -webkit-appearance: none;
  }

  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 20px 0 0;

    .radio {
      height: 20px;
      width: 20px;
      border: 1px solid $gray-10;
      border-radius: 10px;
      border-radius: 50%;
      margin-right: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: inset 0 -3px 0 0 rgba($black, 0.05);
      background-color: $white;
      transition: background-image .3s, box-shadow .3s, border-color .3s;

      &::after {
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: $secondary-color;
        transform: scale(0);
        transition: transform .3s;
      }
    }

    label {
      font-size: 14px;
      line-height: 18px;
    }
  }

  input:checked+label {
    .radio {
      background-image: linear-gradient(180deg, rgba($secondary-color, 0) 0%, rgba($secondary-color, 0.15) 100%);
      box-shadow: inset 0 -2px 0 0 rgba($black, 0.1);
      border-color: $secondary-color;

      &::after {
        transform: scale(1);
      }
    }
  }
}