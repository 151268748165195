.p-dashboard {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  &__filter {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:0;
    width: 80%;

    @include media-up(m) {
      align-items: flex-start;

    }
    
  }
  &__text {
    margin-bottom: 10px;
    padding: 0 10px;
  }
  &__fakefilters {
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    
    @include media-down(m){
      justify-content: center;
    }

    .filter-item {
      position: relative;
      display:flex;
      flex-direction: column;
      border-radius: 3px;
      letter-spacing: -0.2px;
      width: auto;
      background-color: #4c4a4a;
      color:$white;
      margin: 5px;
      padding: 5px 20px 5px 10px;
      transition: background-color .3s;
      .m-advancedFilter__filterButtonKey {
        font-size: 12px;
        line-height: 12px;
      }
      .m-advancedFilter__filterButtonCategory {
        color: #fff;
        margin-top: 2px;
      }
      &.-red  {
        background-color:#cb4242;
        .m-advancedFilter__filterButtonKey {
          text-decoration: line-through;
        }
      }
    }



  }

  .m-savedSearchInfo {
    width: 30%;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;

    &.full-width {
      width: 100%;
    }

    &.half-width {
      width: 49%;
    }

    &.banner {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      // border: 1px solid #4c4a4a;

      .bannerButton {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 5px;
        border:1px solid #4c4a4a;
        height: 66px;
        background-color: $white;
        transition: background-color .3s, color .3s;
        font-size: 18px;
        cursor: pointer;

        &:hover {
          background-color: $secondary-color;
          color: $white;
    
          svg {
            stroke: $white;
          }
        }
    
        svg {
          margin-right: 5px;
          transition: stroke .3s;
        }
      }
    }

    &.custom-width {
      width: 100%;

      // @include media-up(xl) {
      //   width: 49%;
      // }

      &.md {
        @include media-up(m) {
          width: 49%;
        }
      }
    }

    &.-autoHeight {
      height: auto;
      display:flex;
      justify-content: flex-start;

      @include media-down(m){
        width: 100%;
        flex-direction: column;
      }

      .p-dashboard__filter{
        width: 100%;
      }
    }

    .m-regionsMap {
      position: absolute;
      top: 5%;
      left: 0;
      right: 10%;
      bottom: 10%;

      svg path{
        stroke: transparent;
      }

      div.google-visualization-tooltip {
        // background-color: chartreuse;
        // width: 150px;
      }

      div.google-visualization-tooltip > ul > li > span {
        white-space: nowrap;
      }

      &.-country {
        position: absolute;
        top: -20%;
        left: -40%;
        right: 10%;
        bottom: 10%;
        // height: 80%;
        // width: 80%;

        @include media-up(xs) {
          top: 10%;
        }
      }

      @include media-up(xs) {
        top: 10%;
      }

      @include media-up(lg) {
        width: 100%;
      }
    }
  }

  .m-savedSearchInfo {
    display: flex;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);

    &__title {
      margin-bottom: 0;
      font-size:18px;

      &.-clickable {
        cursor: pointer;
      }
    }
    .advanced-filter__Button{
      width: 300px;

      @include media-down(m){
        width: 80%;
        margin: 10px auto;
      }
    }
  }

  &__chart {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
  }

}