.m-dealProcess {
    position: relative;

    &__filter {
        display: flex;
        align-items: flex-end;

        .iconContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 32px;
            width: 32px;
            border-radius: 5px;
            background-color: $white;
            cursor: pointer;
            margin-left: 5px;
            margin-bottom: 6px;
            border: 1px solid #d9dfe4;
            z-index: 2;
        }
    }

    .m-table,
    .m-tab {
        position: relative;
        z-index: 5;
    }

    .-custom {
        width: 400px;
    }

    .m-selectGroup {
        &.-custom {
            z-index: 2;
        }
    }

    .m-tab {
        margin-top: 10px;
        border-radius: 5px;
        flex-direction: column;

        @include media-up(m) {
            flex-direction: row;
        }
    }

    &__blocksContainer {
        position: relative;
        max-width: 95%;
        
        @media (max-width: 1260px) {
            overflow-x: scroll;
            overflow-y: hidden;
        }

        .triangle {
            position: absolute;
            left: 50%;
            top: -100px;
            transform: translateX(-50%);
            z-index: 0;
        }

        &.-blur {
            filter: blur(3px);
        }
    }

    &__blocks {
        min-width: 1160px;
        position: relative;
        display: flex;
        margin: 48px -5px 0;
        z-index: 1;
        @include animation-fade-in;

        transition: transform .2s ease;

        &.-showMore {
            // overflow-x: auto;
            // @include scrollbar();

            .m-dealProcessBlock {
                &.-item15,
                &.-item16 {
                    display: block;
                    flex: 1;
                }
            }

            transform: translateX(-33.333%);
        }
    }

    &__showMore {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        padding: 10px;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: -0.3px;
        color: $white;
        border-radius: 5px;
        background-color: $gray-900;
        border: 0;
        height: 50px;
        cursor: pointer;

        @media (max-width: 1260px) {
            top: 48px;
            display: none;
        }

        svg {
            margin-left: 5px;
            transition: transform .3s;
        }
    }
}