// sass-lint:disable no-vendor-prefixes, no-important, single-line-per-selector force-pseudo-nesting no-duplicate-properties force-attribute-nesting no-qualifying-elements
// Reboot

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: $font-barlow;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba($black, 0);
  overflow-x: hidden;
}

// IE10+ doesn't honor `<meta name='viewport'>` in some cases.
@at-root {
  @-ms-viewport {
    width: device-width;
  }
}

// stylelint-disable selector-list-comma-newline-after
// Shim for 'new' HTML5 structural elements to display correctly (IE10, older browsers)
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

// stylelint-enable selector-list-comma-newline-after

// Body
//
// 1. Remove the margin in all browsers.
// 2. As a best practice, apply a default `background-color`.
// 3. Set an explicit initial text-align value so that we can later use the
//    the `inherit` value on things like `<th>` elements.

body {
  margin: 0; // 1
  font-family: $font-barlow;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: $line-height-base;
  color: $color-text-base;
  text-align: left; // 3
  background-color: #fff; // 2
}

// Suppress the focus outline on elements that cannot be accessed via keyboard.
// This prevents an unwanted focus outline from appearing around elements that
// might still respond to pointer events.
//
// Credit: https://github.com/suitcss/base
[tabindex="-1"]:focus {
  outline: 0 !important;
}

// Content grouping
//
// 1. Add the correct box sizing in Firefox.
// 2. Show the overflow in Edge and IE.

hr {
  box-sizing: content-box; // 1
  height: 0; // 1
  overflow: visible; // 2
}

//
// Typography
//

// Remove top margins from headings
//
// By default, `<h1>`-`<h6>` all receive top and bottom margins. We nuke the top
// margin for easier control within type scales as it avoids margin collapsing.
// stylelint-disable selector-list-comma-newline-after
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: $headings-margin-bottom;
}

// stylelint-enable selector-list-comma-newline-after

// Reset margins on paragraphs
//
// Similarly, the top margin on `<p>`s get reset. However, we also reset the
// bottom margin to use `rem` units instead of `em`.
p {
  margin-top: 0;
  margin-bottom: $paragraph-margin-bottom;
}

ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

// stylelint-disable font-weight-notation
b,
strong {
  font-weight: bolder; // Add the correct font weight in Chrome, Edge, and Safari
}

// stylelint-enable font-weight-notation

small {
  font-size: 80%; // Add the correct font size in all browsers
}

//
// Prevent `sub` and `sup` elements from affecting the line height in
// all browsers.
//

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

//
// Links
//

a {
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent; // Remove the gray background on active links in IE 10.
  -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.
}

//
// Images and content
//

img {
  vertical-align: middle;
  border-style: none; // Remove the border on images inside links in IE 10-.
  max-width: 100%;
}

svg:not(:root) {
  overflow: hidden; // Hide the overflow in IE
}

//
// Tables
//

table {
  border-collapse: separate; 
  border-spacing: unset;
}

th {
  // Matches default `<td>` alignment by inheriting from the `<body>`, or the
  // closest parent with a set `text-align`.
  text-align: inherit;
}

//
// Forms
//

// Remove the default `border-radius` that macOS Chrome adds.
button {
  border-radius: 0;
}

// Work around a Firefox/IE bug where the transparent `button` background
// results in a loss of the default `button` focus styles.
button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0; // Remove the margin in Firefox and Safari
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible; // Show the overflow in Edge
}

button,
select {
  text-transform: none; // Remove the inheritance of text transform in Firefox
}

// 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
//    controls in Android 4.
// 2. Correct the inability to style clickable types in iOS and Safari.
button,
html [type='button'],
// 1
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; // 2
}

// Remove inner border and padding from Firefox, but don't restore the outline like Normalize.
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  // Remove the default appearance of temporal inputs to avoid a Mobile Safari
  // bug where setting a custom line-height prevents text from being vertically
  // centered within the input.
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto; // Remove the default vertical scrollbar in IE.
  // Textareas should really only resize vertically so they don't break their (horizontal) wrappers.
  resize: vertical;
}

fieldset {
  // Browsers set a default `min-width: min-content;` on fieldsets,
  // unlike e.g. `<div>`s, which have `min-width: 0;` by default.
  min-width: 0;
  // Reset the default outline behavior of fieldsets so they don't affect page layout.
  padding: 0;
  margin: 0;
  border: 0;
}

progress {
  vertical-align: baseline; // Add the correct vertical alignment in Chrome, Firefox, and Opera.
}

// Correct the cursor style of increment and decrement buttons in Chrome.
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

//
// Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
//

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

//
// 1. Correct the inability to style clickable types in iOS and Safari.
// 2. Change font properties to `inherit` in Safari.
//

::-webkit-file-upload-button {
  font: inherit; // 2
  -webkit-appearance: button; // 1
}

// Always hide an element with the `hidden` HTML attribute (from PureCSS).
// Needed for proper display in IE 10-.
[hidden] {
  display: none !important;
}