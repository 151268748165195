.m-globalSearchList {
    width: 100%;

    &__wrapper {
        width: 100%;
        max-width: 877px;
        margin: 0 auto;
    }

    &__cantFind {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        border: 1px solid $gray-10;
        border-radius: 3px;
        background-color: $white;
        letter-spacing: -0.2px;
        padding: 15px 20px;
        text-align: center;

        .a-btn {
            font-size: 14px;
            padding: 14px 20px;
            font-weight: 400;
            margin-top: 15px;

            @include media-up(s) {
                margin-top: 0;

            }
        }

        @include media-up(s) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 60px;
            padding: 0 8px 0 20px;
            text-align: left;
        }
    }

    &__block {
        margin-top: 22px;
    }

    &__type {
        font-weight: 600;
        font-size: 20px;
        letter-spacing: -0.25px;
        line-height: 24px;
    }

    &__rows {
        margin-top: 10px;
        border: 1px solid $gray-10;
        border-radius: 3px;
        background-color: $white;
        box-shadow: 0 10px 40px -15px rgba($black, 0.15);
    }

    &__row {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        align-items: center;
        padding: 12px 15px;
        border-bottom: 1px solid rgba($gray-10, .5);

        &:hover {
            background-color: rgba(230, 230, 230, 0.2);
        }

        @include media-up(xs) {
            flex-direction: row;
            padding: 12px 18px 17px;
        }

        .image {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 75px;
            margin-right: 10px;

            div {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba(223, 223, 223, 0.5);
                width: 34px;
                height: 34px;
                border-radius: 3px;
            }
        }

        img {
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // background-color: rgba(223, 223, 223, 0.5);
            width: 75px;
            // height: 34px;
            // border-radius: 3px;
            margin-right: 10px;
        }

    }

    &__rowContent {
        display: flex;
        flex-direction: column;
        margin-top: 15px;

        @include media-up(xs) {
            margin: 0 0 0 10px;
        }
    }

    &__rowName {
        color: $blue-4;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;

        &.-blur {
            filter: blur(2px);
        }
    }

    &__rowInfo {
        margin-top: 5px;
        color: rgba($secondary-color, .7);
        font-size: 13px;
        letter-spacing: -0.16px;
        line-height: 15px;

        &.-blur {
            filter: blur(2px);
        }
    }

    &__more {
        padding: 12px 0 14px;
        color: $blue-4;
        font-weight: 600;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 17px;
        text-align: center;
        cursor: pointer;
        border-radius: 0 0 3px 3px;
        transition: color .3s, background-color .3s;

        &:hover {
            color: $white;
            background-color: $secondary-color;
        }
    }

    &__noResults {
        margin-left: 5px;
        color: rgba($blue-4, .7);
        font-size: 14px;
        letter-spacing: -0.2px;
        line-height: 16px;
    }

}