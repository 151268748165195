.m-statusNotification {
    color: $white;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    border-radius: 20px;
    padding: 5px 15px;
    margin-bottom: 2px;
    display: flex;
    flex-wrap: wrap;

    .statusCount {
        margin-left: 5px;
    }

    &.-green {
        background-color: $notice-green;
    }

    &.-red {
        background-color: $notice-red;
    }

    &.-yellow {
        background-color: $notice-yellow;
    }

    &.-absolute {
        justify-content: center;
        position: absolute;
        top: 5px;
        left: 5px;
        padding: 2px 20px;
        width: 70%;
    }
}