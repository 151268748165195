.m-toggle {
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 100px;
  padding: 15px;
  border-radius: 5px;
  background-color: rgba(1, 143, 208, 0.1);

  & > span {
    color: $gray-16;
    font-size: 16px;
    line-height: 19px;
  }

  &__switch {
    position: relative;
    display: inline-block;
    width: 38px;
    height: 20px;
    margin: 0 10px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + span {

        &::before {
          transform: translate(16px, -50%);
        }
      }
    }

    span {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(1, 143, 208, .15);
      transition: .4s;
      border-radius: 34px;

      &::before {
        position: absolute;
        content: "";
        height: 22px;
        width: 22px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background-color: $secondary-color;
        transition: .4s;
        border-radius: 50%;
      }
    }
  }
}