.d-block {
    display: block !important;
}

.d-none {
    display: none !important;
}

.items-center {
    align-items: center;
}

.cursor-pointer {
    cursor: pointer;
}