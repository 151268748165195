.m-selectGroup {
    width: 100%;
    outline: 0;
    position: relative;

    input {
        padding: 0 20px 0 15px;
        height: 44px;
        font-size: 13px;
    }

    &.-required {
        label {
            &::after {
                position: relative;
                content: '*';
                color: $red;
                right: -5px;
            }
        }
    }

    &.-custom {
        position: relative;
        width: 100%;
        @include media-down(l) {
            width: 100%;
        }
    }

    &__container {
        position: relative;
    }

    &__select {
        width: 100%;
        border: 1px solid $gray-11;
        border-radius: 6px;
        transition: border-color .3s;
        &:focus-visible, &:focus, &:focus-within{
         outline: none;
        }
        

        &.-open {
            outline: 0;
            border-color: $secondary-color;

            ~.m-selectGroup__arrow {
                transform: translateY(-50%) rotate(225deg);
            }
        }

        &.-hide {
            color: transparent;

            &::placeholder {
                visibility: hidden;
            }
        }

        &.-error {
            border-color: $red;
        }

        &.-notOwner {
            cursor: not-allowed;
        }
    }

    &__options {
        position: absolute;
        z-index: 2;
        min-height: fit-content;
        background-color: white;
        border: 1px solid $secondary-color;
        border-radius: 6px;
        width: 100%;
        opacity: 0;
        top: 34px;
        pointer-events: none;
        transition: opacity .3s, top .3s;
        overflow: hidden;
        max-height: 150px;
        overflow-y: auto;
        @include scrollbar;

        &.-open {
            opacity: 1;
            top: 45px;
            pointer-events: initial;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            height: 147px;

            li {
                padding: 5px 10px;
                font-size: 13px;
                cursor: pointer;
                transition: background-color .3s;
                display: flex;
                align-items: center;
                justify-content: space-between;

                &:hover {
                    background-color: rgba($gray-10, 0.5);
                }

                span {
                    font-size: 12px;
                    font-style: italic;
                    margin-right: 10px;
                }
            }
        }
    }

    &__arrow {
        width: 6px;
        height: 6px;
        position: absolute;
        top: 50%;
        right: 10px;
        cursor: pointer;
        border: 1px solid $secondary-color;
        border-width: 0 2px 2px 0;
        padding: 2px;
        transform: translateY(-50%) rotate(45deg);
        transition: transform .3s, border-color .3s;

        &.-notOwner {
            cursor: not-allowed;
        }
    }

    &__searchInput {
        width: 100%;
        background-color: transparent;
        outline: 0;
        border: 2px solid transparent;
        white-space: nowrap;
        position: absolute;
        top: 0;
        left: 0;

        
    }

    &__loader {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gray-400;
        font-size: 12px;
        margin-top: 10px;

        &:hover {
            background-color: $white !important;
            cursor: default;
        }

        .arrow {
            width:30px;
            height:15px;
            display: flex;
            transform: rotate(90deg);
        }
        .arrow:before {
            content: "";
            flex: 1;
            margin: 0 5px;
            background: $gray-400;
            clip-path: polygon(0% 40%, 60% 40%, 60% 0%, 100% 50%, 60% 100%, 60% 60%, 0% 60%);
            animation: a3 .5s infinite alternate;
        }
    
        @keyframes a3 {
            0%  {transform: scaleX(var(--s,1)) translate(-4px)}
            100%{transform: scaleX(var(--s,1)) translate( 4px)}
        }
    }

}