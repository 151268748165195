@mixin createBtn($background, $color: $white) {
  background-color: $background;
  color: $color;

  &:hover {
    background-color: lighten($background, 10%);
  }
}

.btn {
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  padding: 6px 8px;
  margin: 0 5px;
  background-color: $gray-200;
  border-radius: $border-radius;
  color: $gray-800;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 12px;
  letter-spacing: 0.5px;
  border: 1px solid transparent;
  transition: 0.2s;

  &:hover {
    background-color: darken($gray-200, 5%);
  }

  &-primary {
    @include createBtn($color-info);
  }

  &-secondary {
    @include createBtn($gray);

  }

  &-danger {
    @include createBtn($color-error);
  }

  &-success {
    @include createBtn($color-success);
  }
}

.vendor-button {
  width: auto;
  display: inline-block;
  background-color:$gray-10;
  padding:5px 16px;
  border-radius: 15px;
}