.table {
  border-collapse: collapse;
  width: 100%;

  &-empty {
    width: 100%;

    td {
      width: 100%;
      text-align: center;
    }
  }
}

td,
th {
  border: 1px solid $white;
  text-align: center;
  padding: 4px;

  @include media-up(m) {
    padding: 6px;
  }
}

th {
  cursor: pointer;
}

tr:nth-child(even) {
  background-color: $gray-200;
}

.table-action-bar {
  display: flex;
  float: right;

  &.show {
    visibility: visible;
  }

  &.hide {
    visibility: hidden;
  }
}

.table-action-bar-item {
  cursor: pointer;
  padding: 5px;
}

.table-header-item {}