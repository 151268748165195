header {
    background-image: url(../../images/header.png);
    background-size: cover;
    background-position: center;
    background-color: $black;

    overflow-x: hidden;

    // Header Home
    .m-header {
        margin-top: 45px;
        padding-bottom: 50px;


        @include media-up(xl) {
            margin-top: 94px;
            padding-bottom: 0;
        }

        @include media-up(xxl) {
            padding-right: 0;
        }

        &__content {
            display: flex;
            justify-content: center;
            text-align: center;

            @include media-up(xl) {
                justify-content: space-between;
                text-align: initial;
            }

            &--left {

                .a-atom {
                    margin: 12px 0 10px;
                }

                hr {
                    position: relative;
                    border: 0;
                    display: inline-block;
                    margin: 40px 0;
                    width: 100%;

                    @include media-up(xl) {
                        margin: 82px 0 36px;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        height: 1px;
                        background-color: rgba($white, 0.2);
                        width: 100%;
                        left: 0;

                        @include media-up(xxl) {
                            width: calc(100% + 170px);
                        }
                    }
                }

                h1 {
                    font-size: 42px;
                    letter-spacing: -1px;
                    line-height: 60px;
                    color: $white;

                    @include media-up(l) {
                        font-size: 52px;
                    }
                }

                .-blueLine {
                    padding: 12px 0 10px;
                }

                p {
                    color: $white;
                    letter-spacing: 0;
                    line-height: 30px;
                    font-size: 20px;

                    &:nth-of-type(2) {
                        font-weight: 400;
                        opacity: 0.8;
                        margin-bottom: 10px;

                        @include media-up(xxl) {
                            max-width: 420px;
                        }
                    }

                    &:nth-of-type(3) {
                        font-weight: 600;
                    }
                }


            }

            &--right {
                position: relative;
                z-index: 1;


                @include media-up(xl) {
                    padding-left: 50px;

                    &::after {
                        content: "";
                        position: absolute;
                        width: calc(100% + 305px);
                        height: calc(100% + 234px);
                        background-color: rgba($black, 0.5);
                        top: -234px;
                        right: -305px;
                        z-index: -1;
                    }

                    &::before {
                        content: "";
                        position: absolute;
                        width: 2px;
                        background-color: rgba(255, 255, 255, 0.1);
                        height: calc(100% + 234px);
                        top: -234px;
                        left: 0;
                    }
                }

                @include media-up(xxl) {
                    padding-right: 0;
                }

                h3 {
                    font-size: 30px;
                    letter-spacing: -1px;
                    line-height: 40px;
                    color: $white;
                    font-weight: 600;
                    margin-bottom: 20px;
                }

                p {
                    color: $white;
                    letter-spacing: 0;
                    line-height: 26px;
                    margin-bottom: 18px;

                    &:nth-of-type(3) {
                        font-size: 14px;
                        opacity: 0.7;
                        margin-bottom: 9px;
                    }

                    &:nth-of-type(4) {
                        font-size: 14px;
                    }
                }

                .-yellowText {
                    margin-bottom: 0;
                }

                .m-inputGroup {
                    position: relative;
                    height: 60px;
                    margin-bottom: 30px;

                    input {
                        background-color: transparent;
                    }

                    svg {
                        position: absolute;
                        color: $gray-5;
                        right: 17px;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    label {
                        pointer-events: none;
                        position: absolute;
                        top: -20px;
                        left: 20px;
                        color: $white;
                        opacity: 0;
                        transform: translateY(20px);
                        transition: opacity .3s, transform .3s;
                        background-color: transparent;
                        color: $white;
                        padding: 0;
                        font-size: 12px;
                    }

                    .m-inputGroup__error {
                        position: absolute;
                        left: 20px;
                        color: $red;
                        opacity: 0;
                        transition: opacity .3s;

                        &.-active {
                            opacity: 1;
                        }
                    }



                    .a-input {
                        &:focus {

                            +label {
                                opacity: 1;
                                transform: translateY(0);
                            }
                        }
                    }
                }

                .btn-div {
                    text-align: center;
                }
            }

        }

        &__submit {
            margin: 0 auto;
        }

        &__trusted {
            padding-bottom: 40px;

            p {
                font-size: 20px;
                letter-spacing: -0.42px;
                line-height: 30px;
                font-weight: 600;
            }


        }

        &__trustedLogos {
            margin-top: 17px;

            img {
                //temporary
                filter: brightness(0) invert(1);

                margin: 20px 30px;


                @include media-up(m) {
                    margin-top: 0;
                }

                @include media-up(xl) {
                    margin: 0;
                }




                &:not(:last-child) {
                    margin-right: 30px;
                }

                @include media-up(xl) {
                    &:not(:last-child) {
                        margin-right: 60px;
                    }
                }
            }
        }
    }

    // Header Company Owner
    &.-companyOwner {
        background-image: url(../../images/owners-header-image.jpg);
        color: $white;
        padding-bottom: 58px;

        .m-header {
            margin-top: 130px;
        }

        .m-header__content--left {
            @include media-up(xxl) {
                flex: 0 0 44%;
                max-width: 44%;
            }

            h1 {
                font-size: 42px;
                letter-spacing: -1px;
                line-height: 60px;
                color: $white;

                @include media-up(l) {
                    font-size: 52px;

                }
            }

            .-blueLine {
                padding: 17px 0;
            }

            p {
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0;
                line-height: 30px;
                opacity: 0.8;
                margin-bottom: 0;
            }
        }

        .m-nav__logIn {
            top: 55%;

            @include media-up(s) {
                top: 65%;
            }
        }
    }
}