.a-headingLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -0.29px;
    line-height: 25px;

    @include media-up(m) {
        white-space: nowrap;
    }

    &::after {
        content: '';
        display: none;
        height: 1px;
        background-color: $gray-10;
        margin-left: 10px;

        @include media-up(m) {
            display: block;
            width: 100%;
        }
    }
}

.a-headingLines {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -0.29px;
    line-height: 20px;
    padding-right: 20px;

    @include media-up(m) {
        white-space: nowrap;
    }

    &.-small {
        font-size: 12px;

        &::before, &::after {
            opacity: 0.5;
        }
    }

    &.-medium { 
        font-size: 13px;

        &::before, &::after {
            opacity: 0.5;
        }
    }

    &::after {
        content: '';
        display: none;
        height: 1px;
        background-color: $gray-10;
        margin-left: 10px;

        @include media-up(m) {
            display: block;
            width: 100%;
        }
    }

    &::before {
        content: '';
        display: none;
        height: 1px;
        background-color: $gray-10;
        margin-right: 10px;

        @include media-up(m) {
            display: block;
            width: 5%;
        }
    }
}

.a-tagline {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 16px;
    color: $red-4;
    text-align: center;
    margin-bottom: 20px;
}