.m-toggleSwitch {
    display: flex;
    align-items: center;
    height: 50px;
    border-radius: 5px;
  
    & > span {
      color: $gray-16;
      font-size: 13px;
      line-height: 16px;
    }
  
    &__switch {
      position: relative;
      display: inline-block;
      width: 90px;
      height: 25px;
  
      input {
        opacity: 0;
        width: 0;
        height: 0;
  
        &:checked + span {
  
          &::before {
            transform: translate(64px, -50%);
          }
        }
      }
  
      span {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        // background-color: rgba(168, 168, 168, 0.3);
        background-color: $white;
        border: 1px solid $primary-color;
        transition: .4s;
        border-radius: 34px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-left: 0;
        padding-right: 10px;
  
        &::before {
          position: absolute;
          content: "";
          height: 18px;
          width: 18px;
          left: 4px;
          top: 50%;
          transform: translateY(-50%);
          background-color: $primary-color;
          transition: .4s;
          border-radius: 50%;
        }
      }

      span.-checked {
        background-color: rgba($primary-color, 0.7);
        color: $white;
        justify-content: flex-start;
        padding-left: 10px;
        padding-right: 0;
      }
    }
  }