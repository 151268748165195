.m-nav {
    display: flex;
    align-items: center;
    margin: 0 15px;
    justify-content: space-between;
    padding-top: 20px;
    z-index: 2;

    @include media-up(xl) {
        margin: 0 40px;
        position: relative;
    }

    &__left {
        display: flex;
        align-items: center;

        &--logo {
            position: relative;
            z-index: 2;
            filter: brightness(0) invert(1);

            @include media-up(xxl) {
                margin-right: 50px;

            }
        }

        &--links {
            visibility: hidden;
            position: absolute;
            transform: translate3d(100%, 0, 0);
            transition: linear 0.2s;
            width: 100%;
            height: 100vh;
            background-color: $white;
            top: 0;
            right: 0;
            margin-left: 40px;

            @include media-up(xl) {
                visibility: visible;
                position: initial;
                transform: unset;
                transition: unset;
                width: initial;
                height: initial;
                background-color: unset;
            }

            a {
                letter-spacing: 0;
                line-height: 16px;
                color: $white;
                font-size: 19px;
                margin-bottom: 25px;
                text-transform: uppercase;

                @include media-up(xl) {
                    font-size: 14px;
                    margin-bottom: 0;
                    text-transform: capitalize;

                    &:not(:last-child) {
                        margin-right: 42px;
                    }
                }
            }
        }
    }

    &__languagePicker {
        position: relative;
    }

    &__languagePickerContainer {
        display: flex;
        align-items: center;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        margin-right: 20px;

        .a-chevron {
            margin-left: 10px;
        }
    }

    &__languagePickerPopup {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: -22px;
        top: 30px;
        width: max-content;
        min-width: 130px;
        transform: translateY(-20px);
        background: $white;
        padding: 4px;
        box-sizing: border-box;
        border: 1px solid rgba($blue-1, .2);
        border-radius: 10px;
        background-color: $white;
        z-index: 10;
        opacity: 0;
        pointer-events: none;
        animation-name: show;
        animation-duration: .3s;
        transition: opacity .3s, transform .3s;
    
        &.-open {
    
          transform: translateY(0);
          opacity: 1;
          pointer-events: initial;
        }
    
        &::before {
          content: '';
          position: absolute;
          top: -5px;
          right: 40px;
          display: block;
          width: 7px;
          height: 7px;
          background-color: $white;
          border: 1px solid rgba($blue-1, .2);
          border-right: 0;
          border-bottom: 0;
          transform: rotate(45deg);
        }

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 8px 0;
            color: #383f52;
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            cursor: pointer;
            background-color: transparent;
            transition: background-color .3s;
            border-radius: 10px;

            &:hover {
                color: $secondary-color;
            }
        }

        .flagIcon {
            border-radius: 5px;
            width: 25px;
        }
    
    }

    &__logIn {
        visibility: hidden;
        position: absolute;
        transform: translate3d(100%, 0, 0);
        transition: linear 0.2s;
        top: 46%;
        width: 100%;
        right: 0;

        @include media-up(s) {
            top: 40%;
            width: 60%;
        }

        @include media-up(xl) {
            visibility: visible;
            position: initial;
            transform: unset;
            transition: unset;
            width: unset;
        }

        &--info {
            display: flex;
            color: $white;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            margin: 30px 0;

            @include media-up(s) {
                margin: 8px 0 0;
            }

            @include media-up(xl) {
                justify-content: flex-end;
                flex-direction: row;
            }

            p {
                font-size: 15px;
                font-weight: 600;
                letter-spacing: 0;
                line-height: 14px;
                margin-bottom: 0;
                color: $black;

                span {
                    color: $blue-1;
                }

                @include media-up(xl) {
                    font-size: 12px;
                    color: $gray-5;

                    span {
                        color: $white;
                    }
                }
            }

            .line {
                margin: 0 10px 0 15px;
                line-height: 0;
                display: none;
                color: $gray-5;

                @include media-up(xl) {
                    display: block;
                }
            }

            .lang {
                order: -1;
                margin-bottom: 10px;

                @include media-up(xl) {
                    order: initial;
                    margin-bottom: 0;
                }

                span {
                    font-size: 16px;
                    color: $black;
                    cursor: pointer;
                    transition: ease-in-out 0.2s;

                    @include media-up(xl) {
                        font-size: 12px;
                        color: $white;

                        &:hover {
                            color: $orange-1;
                        }
                    }
                }
            }
        }

        &--buttons {
            justify-content: center;
            display: flex;
            align-items: center;
            margin-top: 35px;

            @include media-up(xl) {
                margin-top: 15px;
                justify-content: flex-end;
            }


        }
    }

    &__loginLink {
        color: $black;

        font-size: 12px;
        margin-right: 20px;
        padding: 13px;
        line-height: 1;
        border-radius: 6px;
        background-color: $secondary-color;
        color: $white;

        @include media-up(xl) {
            background-color: unset;
            padding: 0;
            line-height: 14px;
            border-radius: unset;
        }

    }

    &__loggedInButton {
        position: relative;
        display: flex;
        align-items: center;
        margin-left: 20px;
        transition: transform .3s;
        cursor: pointer;

    }


    &__avatarCredentials {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $secondary-color;
        color: $white;
    }

    &__avatarCredentialsDrop {
        position: absolute;
        left: -80px;
        top: 40px;
        padding: 0;
        opacity: 0;
        pointer-events: none;
        padding: 20px;
        background-color: $white;
        border: 2px solid $secondary-color;
        border-radius: 6px;
        cursor: pointer;
        transition: top .3s, opacity .3s;

        &.-active {
            top: 50px;
            opacity: 1;
            pointer-events: initial;
        }

        li {
            &:not(:last-child) {
                border-bottom: 1px solid $secondary-color;
                padding-bottom: 5px;
            }

            &:not(:first-child) {
                margin-top: 5px;
            }
        }

    }

    &__hamburger {
        cursor: pointer;
        border: 1px solid $white;
        padding: 10px;
        z-index: 1;
        border-color: $white;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @include media-up(xl) {
            display: none;
        }

        .one,
        .three {
            width: 31px;
            height: 2px;
            background-color: $white;

            @include media-up(l) {
                background-color: $white;
            }
        }

        .two {
            width: 20px;
            height: 2px;
            background-color: $white;
            margin: 6px 0;

            @include media-up(l) {
                background-color: $white;
            }
        }
    }
}