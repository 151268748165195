.m-backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    background-color: rgba($black, .7);
    display: none;

    &.-show {
        display: block;
    }
}