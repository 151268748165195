// Colors
$gray: #6a737d;
$gray-000: #fafbfc;
$gray-100: #f6f8fa;
$gray-200: #e1e4e8;
$gray-300: #d1d5da;
$gray-400: #959da5;
$gray-500: #6a737d;
$gray-600: #586069;
$gray-700: #444d56;
$gray-800: #2f363d;
$gray-900: #24292e;


$gray-1: #f8f9fa !default;
$gray-2: #e9ecef !default;
$gray-3: #f5f5f9 !default;
$gray-4: #f1f1f9 !default;
$gray-5: #adb5bd !default;
$gray-6: #6c757d !default;
$gray-7: #383f52 !default;
$gray-8: #0b0f1c !default;
$gray-9: #080c19 !default;
$gray-10: #d1d1d9;
$gray-11: #d9dfe4;
$gray-12: #eef0f6;
$gray-13: #fbfbfb;
$gray-14: #0d0915;
$gray-15: #f8fafd;
$gray-16: #212531;
$gray-17: #E9EFF7;


$black: #000;
$white: #fff;

$background-color: #f0f2f3;

$red: #ff6762;
$red-1: #ffe7eb;
$red-2: #d94c4c;
$red-3: #ff223f;
$red-4: #c81e1e;
$green-1: #00bf9c;
$green-2: #2bbCb9;
$green-3:#48ca72;
$color-primary: #383f52;
$color-info: #3498db;
$color-success: #2ecc71;
$color-warning: #f1c40f;
$color-error: #e74c3c;

$notice-green: #00b050;
$notice-red: #c00000;
$notice-yellow: #ffc000;

$blue-1: #4184fc;
$blue-2: #001a6f;
$blue-3: #018fd0;
$blue-4: #001234;
$blue-5: #080616;
$blue-6: #0a86e1;
$blue-7: #2C508C;

$orange-1: #f58e22;

$purple-1: #7536c0;

$brown-1: #a37965;


// Sizing
$border-radius: 2px;

$primary-color: #c81e1e;
$primary-light-color: #cb4242;
$secondary-color: #282828;
$secondary-light-color: #4c4a4a;
$base-color: #d3c9c94d;
$main-color: #F4F4F4;
