.m-process {
    padding: 190px 0 58px;

    &__top {
        justify-content: center;
        margin-bottom: 60px;
        text-align: center;

        @include media-up(xl) {
            text-align: initial;
        }

        h1 {
            letter-spacing: -0.83px;
            line-height: 58px;
            margin-bottom: 22px;
        }

        p {
            letter-spacing: 0;
            line-height: 26px;
            opacity: 0.8;
            color: $blue-4;
        }
    }

    &__card {
        margin-bottom: 30px;

        &--content {
            background-color: $gray-3;
            border: 1px solid rgba($blue-1, .2);
            border-radius: 5px;
            height: 100%;
            padding: 20px;

            @include media-up(s) {
                padding: 30px 30px 40px 34px;
            }

            .flex {
                display: flex;
                align-items: center;
                margin-bottom: 28px;

                img {
                    width: 100px;

                    @include media-up(s) {
                        height: 70px;
                        width: 70px;
                    }
                }

                div {
                    &:nth-of-type(1) {
                        margin-right: 22px;
                    }
                }

                p {
                    margin-bottom: 0;
                    padding-right: 40px;

                    &:nth-of-type(1) {
                        padding-right: 0;
                        font-size: 20px;
                        letter-spacing: 0;
                        line-height: 34px;
                        font-weight: 600;

                        @include media-up(s) {
                            font-size: 24px;
                        }
                    }

                    &:nth-of-type(2) {
                        line-height: 26px;
                        font-weight: 600;
                        padding-right: 0;
                    }
                }
            }

            p {
                margin-bottom: 0;
            }
        }
    }
}