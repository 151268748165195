.m-selectionProcess {
    position: relative;
    
    .m-radioGroup {
        .label {
            color: $white;
        }
    }

    .m-sideModal {
        .m-textareaGroup {
            height: 130px;
        }
    }
}