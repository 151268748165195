.m-progressRing {

    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    
    svg {
        width: 300px;
        padding-left: 15px;
    }
    
    path {
        // fill: transparent;
        fill: rgba(1, 143, 208, 0.2);
        // stroke: transparent !important;
    }

    .successRate {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;

        .value {
            color: #2E384D;
            font-size: 30px;
            line-height: 32px;
            margin-bottom: 5px;
        }

        .title {
            color: #8798AD;
            font-size: 10px;
            line-height: 11px;
            text-transform: uppercase;
        }
    }
}