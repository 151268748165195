.a-input {
    &.-transparent {
        display: block;
        width: 100%;
        background-color: rgba($white, 0.05);
        border-radius: 6px;
        border: 1px solid rgba($white, 0.3);
        padding: 0 47px 0 20px;
        outline: none;
        font-size: 14px;
        position: relative;
        color: $white;
        line-height: 1;

        &::-webkit-input-placeholder {
            transition: ease-in-out 0.2s;
            color: $white;
        }

        &:focus {
            &::-webkit-input-placeholder {
                color: $orange-1;
                transition: ease-in-out 0.2s;
                letter-spacing: 1px;
                font-size: 13px;

                @include media-up(s) {
                    letter-spacing: 2px;
                    font-size: 14px;
                }
            }
        }
    }
}