.m-consultation {
    padding: 80px 0 100px;

    &__content {
        justify-content: space-between;
        align-items: center;

        &--image {
            background-image: url(../../images/consultation.jpg);
            background-size: cover;
            position: relative;
            min-height: 640px;

            @include media-up(s) {
                &::after {
                    content: "";
                    position: absolute;
                    right: -20px;
                    top: 20px;
                    background-color: $gray-4;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                }
            }
        }

        &--text {
            text-align: center;
            margin-top: 60px;

            @include media-up(l) {
                text-align: initial;
                margin-top: 0;
            }

            h2 {
                letter-spacing: -1px;
                line-height: 50px;
                color: $blue-4;
                margin-bottom: 40px;
                font-size: 35px;

                @include media-up(s) {
                    font-size: 40px;
                }
            }

            p {
                color: $blue-4;

                &:nth-of-type(1) {
                    font-size: 20px;
                    letter-spacing: 0;
                    line-height: 30px;
                    margin-bottom: 25px;
                }

                &:nth-of-type(2) {
                    margin-bottom: 35px;
                }
            }

            .-btnEffect {
                &::after {
                    background: $secondary-color;
                }

                &:hover {
                    color: $white;
                }
            }
        }
    }
}