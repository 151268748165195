.m-companyInfo {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 9px 15px;
    margin-top: 15px;
    background-color: $white;
    border: 1px solid $gray-10;
    border-bottom: none;

    @include media-up(m) {
        padding: 9px;
        flex-direction: row;
        align-items: initial;
    }

    &__img {
        min-width: 171px;
        height: 150px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            max-width: 122px;
            min-width: 100%;
        }

        .image {
            height: 100%;
            min-width: 100%;
            background-color: rgba(223, 223, 223, 0.5);
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @include media-up(m) {
            height: 210px;
            margin-right: 30px;
        }
    }

    &__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__selectContainer {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    &__select {
        display: flex;
        flex-wrap: wrap;

        .m-selectGroup {
            z-index: 3;
            margin: 0 10px;
            // &:last-child {
            //     margin-left: 10px;
            // }
        }
        .m-selectGroup:first-child {
            z-index: 4;
        }
    }

    &__name {
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.2px;
        color: $blue-4;
        margin-top: 15px;
        font-weight: 600;
        display: flex;
        align-items: center;
    }

    &__subsidiary {
        background-color: #ffe695;
        border: 1px solid #ffdb60;
        font-size: 11px;
        margin-left: 10px;
        padding: 2px 10px;
        border-radius: 6px;
        font-weight: 600;
        color: #a07b00;
        letter-spacing: 0.2px;
        line-height: 15px;
    }

    &__note {
        position: absolute;
        top: 5px;
        right: 15px;
        display: flex;
        align-items: center;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.5px;
        color: $blue-4;
        cursor: pointer;

        @include media-up(m) {
            top: 14px;
            right: 14px;
        }

        svg {
            margin-right: 3px;
        }
    }

    &__title {
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.2px;
        color: $blue-4;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &__description {
        font-size: 13px;
        line-height: 23px;
        color: $blue-4;
        opacity: 0.7;
    }

    &__status {
        border-radius: 10px;
        padding: 10px 3px;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: $white;
        width: 120px;
        margin-top: 10px;

        &.-green {
            background-color: #0c810c;
        }
        &.-red {
            background-color: #d30919;
        }
    }

    &__contact {
        display: flex;
        flex-wrap: wrap;
        font-size: 13px;
        line-height: 18px;
        color: $blue-4;
        margin-top: 10px;

        span {
            display: flex;
            align-items: center;
            margin: 5px 20px 5px 0;

            svg {
                margin-right: 5px;
            }
        }
    }

    &__owner {
        font-weight: bold;
    }

    &__tagList {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 15px;

        span {
            background-color: rgba($secondary-light-color, 0.1);
            padding: 8px 14px;
            border-radius: 5px;
            margin: 5px 5px 5px 0;
            font-size: 12px;
            line-height: 16px;
            white-space: nowrap;
            color: rgba($secondary-color, 0.8);
            transition: color 0.3s, background-color 0.3s;
            cursor: pointer;

            &:hover {
                background-color: rgba($secondary-light-color, 0.2);
                color: $secondary-color;
            }

            &.-empty {
                &:hover {
                    background-color: rgba(#b4b9c4, 0.3);
                    color: rgba($blue-4, 0.8);
                    cursor: default;
                }
            }
        }
    }
}
