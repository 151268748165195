// Wrapper widths
// Set the wrapper width with media queries.
@if $enable-grid-classes {
  ._wr  {
    @include make-wrapper;
    @include make-wrapper-max-widths;
  }
}

// Fluid wrapper
// Utilizes the mixin meant for fixed width wrappers, but with 100% width for
// fluid, full width layouts.
@if $enable-grid-classes {
  ._wr-f {
    @include make-wrapper;
  }
}

// Wrap
// Wraps contain and clear the floats of your columns.
@if $enable-grid-classes {
  ._w {
    @include make-wrap;
  }

  // Remove the negative margin from default .wrap, then the horizontal padding
  // from all immediate children columns (to prevent runaway style inheritance).
  .no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="_"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

// Columns
// Common styles for small and large grid columns
@if $enable-grid-classes {
  @include make-grid-columns;
}
