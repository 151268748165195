.m-potential {
    position: relative;
    padding: 100px 0;
    z-index: 2;
    background-image: url(../../images/potential.png);
    background-size: cover;
    background-position: center;
    background-color: $gray-9;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(270deg, rgba($gray-9, 0) 0%, rgba($gray-9, 0.7) 100%);
    }

    &__content {
        justify-content: center;
        text-align: center;

        @include media-up(xl) {
            justify-content: initial;
            text-align: initial;
        }
    }

    h3 {
        font-weight: 400;
        letter-spacing: -0.5px;
        line-height: 40px;
        color: $white;
        opacity: 0.5;
        margin: 20px 0 10px;
    }

    h2 {
        letter-spacing: -1px;
        line-height: 50px;
        color: $white;
    }

    .-blueLine {
        padding: 30px 0 35px;
    }

    p {
        color: $white;
        letter-spacing: 0;

        &:nth-of-type(2) {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 25px;
        }

        &:nth-of-type(3) {
            line-height: 26px;
            opacity: 0.7;
            margin-bottom: 0;
        }
    }
}