.m-checkboxGroupContainer {
  position: relative;
  margin-top: 20px;

  &.-required {
    .m-checkboxGroupContainer__label {
        &::after {
            position: relative;
            content: '*';
            color: $red;
            right: -5px;
        }
    }
  }

  .m-checkboxGroup {
    margin-top: 0;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  &__options {

    &.-horizontal {
      display: flex;
    }
  }
}