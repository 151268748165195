.m-list {
  padding: 0;

  &.-underline {

    li {
      min-height: 30px;
      font-size: 12px;
      padding: 10px 0;
    }
  }
}