.m-table {
    margin-top: 30px;
    table-layout: fixed;

    @include animation-fade-in;

    ::-webkit-scrollbar {
        height: 6px;
    }

    ::-webkit-scrollbar-track {
        background-color: rgba($secondary-color, 0.2);
        border-radius: 4px;
        opacity: 0.5;
    }

    ::-webkit-scrollbar-thumb {
        background: $secondary-color;
        border-radius: 4px;
    }

    &__container {
        display: block;
        width: 100%;
        overflow-x: auto;

        &.-ofInitial {
            overflow: initial;
        }

        .m-companyInfo__subsidiary {
            display: inline-block;
            margin: 5px;
        }
    }

    table {
        width: 99.9%;
        max-width: 99.9%;
        margin: 0 auto;
    }

    thead {
        font-size: 14px;
        line-height: 14px;
        letter-spacing: -0.2px;

        th {
            border: none;
            padding: 12px 30px;
            border-bottom: 1px solid $gray-10;

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;

                .sortIcon {
                    margin-left: 5px;
                    width: 12px;
                    height: 12px;
                }
            }
            &.fixed {
                position: sticky;
                z-index: 17;
                background-color: $main-color;
            }
        }
    }

    tbody {
        &.-blur {
            filter: blur(3px);
            pointer-events: none;
        }

        td {
            padding: 12px 35px;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: -0.15px;
            border-right: 1px solid $gray-10;
            border-bottom: 1px solid $gray-10;
            border-top: none;
            border-left: none;

            &:first-child {
                border-left: 1px solid $gray-10;
            }
            &:last-child {
                min-width: 50px;
                padding: 0;
            }

            @include media-down(xs) {
                padding: 10px;
                font-size: 12px;
            }
            .note-value {
                min-width: 250px;
            }

            .image-name {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                cursor: pointer;
                text-align: left;

                span {
                    min-width: 34px;
                    min-height: 34px;
                    width: 34px;
                    height: 34px;
                    border-radius: 3px;
                    margin-right: 10px;
                }

                img {
                    width: 75px;
                    border-radius: 3px;
                    margin-right: 10px;
                }

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba(223, 223, 223, 0.5);
                }

                &.-blur {
                    filter: blur(2px);
                    cursor: default;
                }
            }

            .image-logo {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                width: 75px;

                @include media-down(xs) {
                    width: 77px;
                }

                &.-noticeSpace {
                    margin-top: 25px;
                }

                span {
                    min-width: 34px;
                    min-height: 34px;
                    width: 34px;
                    height: 34px;
                    border-radius: 3px;
                }

                img {
                    width: 75px;
                    min-width: 75px;
                    border-radius: 3px;
                }

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba(223, 223, 223, 0.5);
                }
            }

            .vendors-logo {
                display: flex;
                flex-direction: column;
                align-items: center;

                img {
                    max-width: 70px;
                    margin: 10px 0;
                }
            }

            .logo {
                img {
                    width: 80px;
                    max-width: 80px;
                    border-radius: 3px;
                    margin-right: 10px;
                }
            }

            .flag {
                // height: 15px;
                width: 25px;
            }

            .blur {
                filter: blur(2px);
            }

            .avatar-name {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                img,
                .image {
                    width: 24px;
                    height: 24px;
                    min-width: 24px;
                    min-height: 24px;
                    border-radius: 50%;
                    margin-right: 10px;
                }

                .image {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: rgba(223, 223, 223, 0.5);
                }

                span {
                    text-align: left;
                }
            }

            .status {
                &.active {
                    color: $green-1;
                }

                &.inactive {
                    color: $red;
                }
            }
            &.fixed {
                position: sticky;
                z-index: 18;
            }
            &.grey-row {
                background-color: inherit !important;
            }
            &.white-row {
                background-color: inherit !important;
            }
            .note-list {
                padding: 0;
                width: auto;
                li {
                    width: auto;
                    text-align: left;
                    padding: 2px 6px;
                    background-color: #e4e4e3;
                    margin: 2px 0;
                    color: #474645;
                    border-radius: 3px;
                    border: 1px dotted #333;
                    .note-history {
                        font-weight: 700;
                        color: #474645;
                    }
                    .note-date {
                        font-style: italic;
                        font-size: 12px;
                    }
                    span {
                        display: block;
                    }
                }
            }
            .a-historyNote {
                padding: 5px;
                border: unset;
                border-radius: 3px;
                width: 120px;
                margin: 10px auto;
                outline: none;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 24px;
                background-color: #e0c6ab;
                color: #333;

                &.-show {
                    background-color: #bebebe;
                }
                &.-hide {
                    background-color: #494847;
                }

                .a-chevron {
                    margin-left: 10px;
                    margin-top: 5px;
                }
                &.a-chevron.-up {
                    border-right-color: rgba(56, 63, 82, 0.5);
                    border-bottom-color: rgba(56, 63, 82, 0.5);
                    transform: rotate(225deg);
                }
                .a-chevron.-down {
                    border-right-color: #282828;
                    border-bottom-color: #282828;
                    transform: rotate(45deg);
                    margin-top: unset;
                }
            }

            &.-selectionColumn {
                background-color: rgba($primary-color, .15) !important;
                padding: 0 !important;
                position: relative;
                
            }

            &.-selectionColumnPipeline{
                position: relative;

                .a-chevron {
                    margin:0 10px;
                }
            }
        }

        td:nth-child(n + 3) {
            width: auto;
            // min-width: 60px;
            padding: 10px;
        }
        td:first-child {
            @include media-landscape() {
                width: 77px !important;
                padding: 0;
            }

            @include media-down(xs) {
                width: 77px;
                padding: 0;
            }
        }

        tr:nth-child(odd) {
            background-color: $white;
        }

        tr:nth-child(even) {
            background-color: $gray-13;
        }

        tr {
            transition: background-color 0.2s ease;

            &:hover {
                cursor: default;
                background-color: #ededed;
            }

            &:first-of-type,
            &:nth-of-type(2) {
                .m-isInterested {
                    &__options {
                        top: 20px;
                    }
                }
            }

            &.-orange {
                background-color: #ffe8be;
            }

            &.-red {
                background-color: #ffd9d9;
            }

            &.-gray {
                background-color: #e4e4e4;
            }
        }

        tr.clickable {
            &:hover {
                cursor: pointer;
                background-color: #f2f2f2;
            }
        }
    }

    &__actions {
        text-align: right;

        span {
            cursor: pointer;
            margin-left: 15px;
        }
    }

    &__selected {
        display: flex;
        align-items: center;
        height: 18px;

        svg {
            fill: $green-1;
            margin-right: 5px;
        }
    }

    .pagination-container {
        left: 0;
        margin-top: 20px;
    }

    .actionButton {
        &.-disabled {
            cursor: not-allowed;
            svg {
                opacity: 0.5;
            }
        }
    }

    .invitationButton {
        color: white;
        padding: 5px 15px;
        border-radius: 6px;
        cursor: pointer;
        font-weight: 600;

        &.-accept {
            background-color: $color-success;
        }

        &.-reject {
            background-color: $color-error;
        }

        &.-disabled {
            background-color: $gray-300;
            cursor: default;
        }

        &.-kicked-out {
            background-color: $color-warning;
            padding: 5px 5px;
        }

        &.-disabled-kicked-out {
            background-color: $gray-300;
            padding: 5px 5px;
            cursor: default;
        }
    }

    .invitationStatus {
        font-weight: bold;
        letter-spacing: 0.5px;

        &.-pending {
            color: $gray-400;
        }

        &.-accepted {
            color: $color-success;
        }

        &.-rejected {
            color: $color-error;
        }

        &.-kicked-out {
            color: $color-warning
        }
    }

}
